.milestones-container {
  border-top: 1px solid black;
  padding: 20px;
}

.create-milestone-btn {
  border: none;
  outline: none;
  padding: 10px 18px;
  font-weight: 500;
  /* background: linear-gradient(to right, #f7f8fe, #e7e7e7); */
  background: transparent;
  border: 2px solid #5E72E4;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-top: 20px;
  color: #5E72E4;
}

.create-milestones-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #fff;
}

.create-milestones-container h3 {
  font-size: 22px;
  font-weight: 400;
  margin: 0px;
  color: rgb(111, 117, 138);
}

/*

rgb(111, 117, 138)
rgb(137, 145, 174)

*/

.create-milestones-container .mile-form-container {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.create-milestones-container .mile-form-container .mile-form-field-row {
  display: flex;
  align-items: stretch;
  width: 70%;
  margin-top: 10px;
  /* border: 1px solid rgba(0, 0, 0, 0.16); */
}

.create-milestones-container .mile-form-field-row .mile-form-field-desc,
.create-milestones-container .mile-form-field-row .mile-form-field-input {
  width: 50%;
}

.create-milestones-container .mile-form-field-row .mile-form-field-desc p {
  font-size: 16px;
  margin: 0;
  font-weight: 400;
}

.create-milestones-container .mile-form-field-row .mile-form-field-desc span {
  font-size: 13px;
  font-weight: 400;
  color: rgb(137, 145, 174);
}

.create-milestones-container .mile-form-field-row .mile-form-field-input {
  /* display: flex; */
  /* align-items: center; */
}

.create-milestones-container .mile-form-field-row .mile-form-field-input input,
.create-milestones-container .mile-form-field-row .mile-form-field-input select {
  padding: 4px 10px;
  border: none;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.56);
  width: 70%;
  color: #5E72E4;
}

.create-milestones-container .mile-form-field-row .mile-form-field-input select {
  background: transparent;
  color: #5E72E4;
  padding-block: 9px;
}

.create-milestones-container .mile-form-field-row .mile-form-field-input input::placeholder {
  color: #5E72E4;
  font-size: 15px;
}

.mile-create-btn {
  border: none;
  outline: none;
  padding: 0px 0px;
  width: 80px;
  font-weight: 500;
  /* background: linear-gradient(to right, #f7f8fe, #e7e7e7); */
  background: #5E72E4;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  color: #fff;
  line-height: 40px;
}


.mile-cancel-btn {
  border: none;
  outline: none;
  padding: 0px 0px;
  width: 80px;
  font-weight: 500;
  background: linear-gradient(to right, #f7f8fe, #e7e7e7);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  color: #000;
  line-height: 40px;
}

.milestones-holder {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

.milestones-holder .milestone-trackcard {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 14px 18px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position: relative;
  cursor: pointer;
}

.cicprogr-hldr {
  width: 60px;
}

.milestones-holder .milestone-trackcard p {
  margin: 0;
}

.milestone-trackcard .milestone-title {
  font-weight: 600;
  font-size: 15px;
}

.milestone-trackcard .milestone-title-subhead {
  font-weight: 500;
  font-size: 14px;
  color: rgb(111, 117, 138);
}

.milestone-trackcard .milestone-title-subhead span {
  font-weight: 700;
}

.milestone-addnew-btn {
  transition: all 300ms ease;
  cursor: pointer;
}

.milestone-addnew-btn:hover {
  background: linear-gradient(to right, #f7f8fe, #e7e7e7);
}

.milestone-bdg-delete {
  position: absolute;
  top: -4px;
  left: 0;
  margin: 0;
  padding: 0px;
  color: #000;
  line-height: 1;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  font-size: 22px;

}

.milestone-badge {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 4px;
  background: linear-gradient(to right, #f7f8fe, #e7e7e7);
  color: #000;
  line-height: 1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.milestone-bdg-xpired {
  background: linear-gradient(to right, #8e9eab, #eef2f3);
  padding-inline: 10px;
  color: #000;
  font-weight: 600;
  font-size: 13px;
}

.milestone-bdg-live {
  background: linear-gradient(to right, #1d4975, #3498db);
  padding-inline: 10px;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
}

.milestone-btm-action-tray {
  /* border-top: 1px solid rgb(111, 117, 138); */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-inline-end: 20px;
}


.milestone-btm-action-tray button {
  color: rgb(41, 80, 219);
  font-family: Inter, "Inter Fallback Arial", Arial;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  text-decoration-line: none;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  margin: 0px;
  padding: 4px 10px;
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 4px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; */
  /* border: 1px solid; */
  width: fit-content !important;
}

.mile-delete-btn {
  border: none;
  outline: none;
  padding: 0px 0px;
  width: 80px;
  font-weight: 500;
  background: linear-gradient(to right, #ffdcd7, #ffdcd7);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  color: #dd1414;
  line-height: 40px;
}

.overview-refresh-btn {
  color: rgb(41, 80, 219);
  font-family: Inter, "Inter Fallback Arial", Arial;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  text-decoration-line: none;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  margin: 0px;
  padding: 0px;
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 4px;
}