@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Francois+One&family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&family=Hina+Mincho&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

* {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  overflow-x: hidden;
  font-family: DM Sans, sans-serif;
  background-color: #f7f8fe;
  box-sizing: border-box;
}

:root {
  --light-pink: #ffc0bc;
  --light-blue: #b5c6ff;
  --main-black: #343A40;
  --primary-black: #1f1f1f;
  --main-blue: #1039c2;
  --dark-gray: #575757;
  --light-gray: #8c8c8c;
  --primary-white: #d9d9d9;
  --primary-violet: #024c87;
  --primary-blue: #576bad;
  --secondary-blue: #4f5a80;
  --main-white: #fff;
}

html {
  overflow-x: hidden;
}

a,
a:hover {
  text-decoration: none;
  transition: 0.3s;
  color: inherit;
}

a:hover {
  color: inherit;
}

.cursor-pointer {
  cursor: pointer;
}

/*********************************** Side bar Pge CSS ************************************************************/

.sidebar_container {
  background-color: #343A40;
  width: 100%;
  padding: 20px 10px;
  position: relative;
  height: 100vh;
  padding-left: 18px;
}

.sidebar_container .sidebar_logo {
  margin-bottom: 20px;
}

.sidebar_container .sidebar_logo img {
  width: 60%;
}

.sidebar_container .sidebar_list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar_container .sidebar_list ul li {
  padding: 10px 18px;
  width: 100%;
  color: var(--primary-white);
}

.sidebar_container .sidebar_list ul li a {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--main-white);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 144.5%;
  /* 23.12px */
  text-transform: capitalize;
}

.sidebar_container .sidebar_list ul .isactive {
  background-color: var(--primary-violet);
  border-radius: 4px;
  position: relative;
}

.sidebar_container .sidebar_list ul .isactive::before {
  content: "";
  position: absolute;
  left: 3%;
  padding: 0px 2px;
  width: 1%;
  height: 60%;
  border-radius: 3px;
  background-color: var(--main-white);
}

.sidebar_container .sidebar_container_navtext {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.sidebar_profile_box {
  position: absolute;
  bottom: 3%;
  left: 0;
  width: 100%;
  padding: 10px;
  padding-left: 18px;
}

.sidebar_container .sidebar_profile_tab {
  display: flex;
  align-items: center;
  background-color: var(--primary-violet);
  padding: 10px;
  width: 100%;
  justify-content: space-between;
  border-radius: 6px;
  cursor: pointer;
}

.sidebar_container .sidebar_profile_tab span {
  color: #fff;
  font-size: 18px;
}

.sidebar_container .sidebar_profile_tab svg {
  fill: var(--main-white);
  font-size: 22px;
}

.sidebar_container .sidebar_profile_tab img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.sidebar_container .sidebar_profile_tab h3 {
  color: var(--main-white);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 24px */
  text-transform: capitalize;
  margin: 0;
}

.sidebar_container .sidebar_profile_tab h4 {
  color: var(--main-white);
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
}

/**************************************************** Header CSS ********************************************************************/

.header_container {
  background-color: var(--main-white);
  border-bottom: 1px solid #e2e2e2;
  padding: 12px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_container .header_breadcrumb {
  display: flex;
  gap: 10px;
}

.header_container .header_breadcrumb a {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #8c8c8c;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 144.5%;
  /* 23.12px */
  text-transform: capitalize;
}

.header_container .header_profile_details {
  display: flex;
  align-items: center;
  gap: 22px;
  padding-right: 15px;
}

.header_container .header_profile_details .header_profile_details_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.header_container .header_profile_details svg {
  scale: 1.2;
}

.header_container .header_profile_details .header_profile_bellicon {
  position: relative;
  cursor: pointer;
}

.header_container .header_profile_details .header_profile_bellicon.active::before {
  position: absolute;
  height: 12px;
  width: 12px;
  background-color: red;
  border-radius: 50%;
  right: -15%;
  top: -20%;
  content: "";
  z-index: 3;
}

.forms_table_container {
  border-radius: 5px;
  background: #fff;
  padding: 8px 12px;
  height: 90vh;
  overflow-y: auto;
}

.form_table_header_container .form_table_header_left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.form_table_header_container .form_table_header_left svg {
  fill: var(--primary-violet);
  font-size: 18px;
}

.form_table_header_container .form_table_header_left span {
  color: #024c87;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.form_table_navs_container .form_table_navs {
  display: flex;
  align-items: center;
  gap: 24px;
}

.form_table_navs_container {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
}

.form_table_navs_container .form_table_navs a {
  padding-bottom: 6px;
  color: #7d8185;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.form_table_navs_container .form_table_navs a.active {
  color: var(--primary-violet);
  border-bottom: 2px solid var(--primary-violet);
}

/******************************************* Table Template Style *************************************************************/
.table-template-style {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.table-template-style thead tr th {
  color: var(--primary-blue);
  font-family: "DM Sans";
  padding: 10px;
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 0.08);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  min-width: 180px;
}

.table-template-style thead tr .table-first-element {
  min-width: 80px;
}

.table-template-style tbody tr td {
  /* background-color: var(--main-white); */
  padding: 10px;
  font-family: "DM Sans";
  color: var(--light-gray);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.table-template-style tbody tr {
  margin-bottom: 10px;
}

.table-template-style tbody tr:nth-child(even) {
  border-radius: 5px;
  background: #f6f6f6;
  box-shadow: 0px 2px 0px 0px #ebebeb;
}

.table-template-style tbody tr td img {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  object-fit: cover;
}

.table-template-style tbody tr td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.table-template-style tbody tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

/************************************ Scrollbar css ********************************************/
.scrollbar-template::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.scrollbar-template::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 6px;
}

/* Handle */
.scrollbar-template::-webkit-scrollbar-thumb {
  background: var(--primary-blue);
  border-radius: 10px;
}

/* Handle on hover */
.scrollbar-template::-webkit-scrollbar-thumb:hover {
  background: var(--primary-blue);
}

.module_student_table {
  /* height: 70vh; */
  overflow-y: auto;
  margin: 0px 10px;
  display: block;
  margin-top: 32px;
}

/*********************************************** Login Page CSS *****************************************************/
.login_container {
  padding: 80px 0px;
  height: 100vh;
  background-color: #181a25;
  background-image: url(/public/topography.svg);
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.login_container::before {
  position: absolute;
  content: "";
  display: block;
  height: 400px;
  width: 351px;
  background-image: url(/public/hero-shape-1.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  top: 0;
  left: 0;
  z-index: 0;
  transform: translateX(-50%);
}

.login_container::after {
  position: absolute;
  content: "";
  display: block;
  height: 100px;
  width: 100px;
  background-image: url(/public/hero-shape-3.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  top: 50%;
  left: 25%;
  z-index: 0;
  transform: translateX(-50%);
  animation: rotateimg 10s linear infinite forwards;
}

@keyframes rotateimg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login_container .loginform-formik-holder {
  padding: 50px 10px;
  width: 70%;
}

.login_container .loginform-formik-holder h4 {
  color: white;
  margin-block-end: 20px;
}

.login_container .row {
  height: 100%;
}

.loginform_container {
  border-radius: 12px;
  padding: 16px 22px;
  /* background: rgba(255, 255, 255, 0.45);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(6px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18); */
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
}



.loginform_container form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
}

.loginform_container form .loginform-input-wr {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  position: relative;
}

.loginform_container form .loginform-input-wr span {
  color: rgb(255, 58, 134);
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
}
.loginform_container form button {
  padding: 0px 0px;
  color: #fff;
  font-weight: 500;
  margin: 0 auto;
  /* background: linear-gradient(to right,  #1d7bf6, rgb(255, 58, 186)); */
  background: linear-gradient(to right,  #40a9ff, #065ed2);
  font-size: 18px;
  border-radius: 5px;
  width: 100%;
  line-height: 52px;
  outline: none;
  border: none;
}

.loginform_container form input {
  width: 100%;
  padding: 9px 15px;
  border: none;
  outline: none;
  /* border-radius: 6px; */
  background: rgba(217, 217, 217, 0.38);
  border-bottom: 2px solid #1fbeef;
  color: white;
  font-size: 14.5px;
}

.loginform_container form input.inp-formik-err{
  border-color: rgb(255, 58, 134) !important;
}

.eye-password-formik {
  position: absolute;
  right: 10px;
  bottom: 5px;
  color: #fff;
  font-size: 22px;
  cursor: pointer;
}

.loginform_container form input::placeholder {
  color: #fff;
}

.loginform_container .loginform_title {
  margin: 0px 0px;
}

.loginform_container .loginform_title h2 {
  color: #fff;
  font-family: "Inter";
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
  text-align: center;
}

.loginform_container .loginform-formik-holder .formik-error-message {
  color: #ff5e83;
  background: #FF748B4a;
  padding: 8px 12px;
  font-weight: 500;
  font-size: 16.5px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.loginform_container .loginform_title h2 span {
  background: linear-gradient(to right, #1fbeef, rgb(255, 81, 214));
  background-clip: text;
  color: transparent;
}

.loginform_container .loginform_inp {
  border-radius: 5px;
  border: 1px solid var(--primary-white);
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
}

.loginform_container .loginform_inp svg {
  fill: var(--light-gray);
  font-size: 22px;
}

.loginform_container .loginform_inp input {
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
}

.loginform_container .loginform_inp_forgot {
  display: flex;
  justify-content: end;
}

.loginform_container .loginform_inp_forgot a {
  color: var(--light-gray);
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 120% */
}

.loginform_container .loginform_submit {
  display: flex;
  justify-content: center;
  margin-top: 72px;
}

.loginform_container .loginform_submit button {
  border-radius: 5px;
  background: var(--primary-violet);
  padding: 7px 62px;
  color: var(--main-white);
  font-family: "DM Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 85.714% */
  border: 1px solid var(--primary-violet);
}

.loginform_container .loginform_submit_other_device {
  margin-top: 16px;
}

.loginform_container .loginform_submit_other_device button {
  width: 100%;
  padding: 12px;
}

.loginimg_container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

/************************************************************ Custom Toast *****************************************************/

.custom-toast h3 {
  color: var(--primary-black);
  font-family: "DM Sans";
  font-size: 22px;
  font-style: normal;
  line-height: normal;
}

.custom-toast .custom-toast-div {
  display: flex;
  justify-content: end;
  gap: 12px;
  margin-top: 12px;
}

.custom-toast .custom-toast-div button {
  padding: 8px 14px;
  color: var(--main-white);
  border-radius: 6px;
  font-weight: 700;
  font-family: "DM Sans";
  font-size: 14px;
}

.custom-toast .custom-toast-div .toast-btn-yes {
  background-color: #11a63b;
  border: 1px solid #11a63b;
}

.custom-toast .custom-toast-div .toast-btn-no {
  background-color: #e30e00;
  border: 1px solid #e30e00;
}

.form_error_div {
  color: #dc3545;
  font-size: 0.875em;
  margin-top: 0.25rem;
  width: 100%;
}

.react_paginate_Div ul {
  display: flex;
  gap: 20px;
  list-style: none;
  justify-content: end;
  margin-top: 20px;
  flex-wrap: wrap;
  padding: 0;
}

.react_paginate_Div ul li {
  padding: 6px 18px;
  border-radius: 8px;
}

.react_paginate_Div ul .active {
  background-color: var(--primary-violet);
  color: #ffff;
}

.form_table_nav_search button,
.form_table_nav_search button:hover {
  background-color: var(--primary-violet);
  color: #fff;
}

.form_table_nav_search {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.form_table_nav_search input:focus {
  box-shadow: none;
  border-color: none;
}

.form_options_container {
  position: relative;
}

.form_options_container::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 90%;
  width: 2px;
  background-color: #fff;
}

.form_options_container .form_options_div {
  background-color: #31333a;
  color: #fff;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 8px;
  display: inline-block;
  width: 90%;
  margin-left: 32px;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 4px;
}

.form_options_container .form_options_div .svg-arrow {
  position: absolute;
  left: -29px;
  top: 29%;
  scale: 1.4;
}

.form_options_container a {
  margin-left: 38px;
  padding-left: 7px;
  margin-bottom: 6px;
}

.form_options_container a.active {
  border-left: 3px solid var(--main-white);
}

.form_table_nav_search .form_table_nav_input {
  border-radius: 8px;
  border: 1px solid var(--primary-violet);
  padding: 4px;
  display: flex;
  align-items: center;
  width: 40%;
}

.form_table_nav_search .form_table_nav_input button {
  border-radius: 9px;
  background: var(--primary-violet);
  padding: 4px 8px;
  color: var(--main-white);
  text-align: center;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  border: 1px solid var(--primary-violet);
}

.form_table_nav_search .form_table_nav_input button svg {
  font-size: 22px;
}

.form_table_nav_search .form_table_nav_input input {
  width: 100%;
  padding: 8px;
  border: none;
  outline: none;
}

.form_table_navs_container .form_table_nav_filter button {
  border-radius: 33px;
  background: #ddf0ff;
  padding: 10px 18px;
  color: #024c87;
  text-align: center;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 6px;
  border: 1px solid #ddf0ff;
}

.form_table_navs_container .form_table_nav_page select {
  outline: none;
  border-radius: 33px;
  background: #ddf0ff;
  padding: 10px 20px;
  padding-right: 36px;
  color: #024c87;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 6px;
  border: 1px solid #ddf0ff;
  appearance: none;
  -webkit-appearance: none;
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-image: url("../images/angle-blue.png");
}

.filter_options_container_hide {
  display: none !important;
}

.filter_options_container {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 28%;
  box-shadow: 0px 0px 6px 0px #c3c3c3;
  background: #fff;
  height: 85%;
  padding: 8px 12px;
  overflow-y: auto;
  display: block;
}

.filter_options_container .filter_options_heading h3 {
  color: #242220;
  font-family: "DM Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: normal;
  margin-bottom: 20px;
}

.filter_options_container .filter_options_labels {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter_options_container .filter_options_batch_label {
  border: 1px solid #cccccc;
  padding: 6px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.filter_options_container .filter_options_div {
  margin-bottom: 24px;
  padding: 0px 10px;
}

.filter_options_container .filter_options_labels svg {
  font-size: 22px;
  cursor: pointer;
}

.filter_options_container .filter_options_labels label {
  color: #242220;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}

.filter_options_container .filter_options_batch_label label {
  margin: 0;
}

.filter_options_container .filter_radio_div {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 14px;
  margin-bottom: 20px;
}

.filter_options_container .filter_radio_div input {
  scale: 1.3;
}

.filter_options_container .filter_radio_div label {
  color: #575757;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.filter_options_container .filter_options_inp {
  border-radius: 8px;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
}

.filter_options_container .filter_options_inp span {
  color: #b3b3b3;
  text-align: center;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.filter_options_container .filter_options_inp span svg {
  font-size: 24px;
  cursor: pointer;
}

.filter_options_container .filter_options_course {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
}

.filter_options_course .filter_options_course_item {
  border-radius: 34px;

  color: #fff;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding: 8px 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  border: 1px solid #575757;
  color: #575757;
  cursor: pointer;
}

.filter_options_course .filter_options_course_item svg {
  display: none;
}

.filter_options_course .filter_options_course_item.active {
  background: #80a5c2;
  border: 1px solid #80a5c2;
  color: #fff;
}

.filter_options_course .filter_options_course_item.active svg {
  display: inline-block;
}

.filter_options_container .filter_options_course {
  margin-top: 14px;
}

.filter_options_container .filter_options_calendar {
  margin-top: 12px;
  display: flex;
  justify-content: center;
}

.filter_options_container .filter_options_close {
  position: absolute;
  top: 18px;
  font-size: 24px;
  cursor: pointer;
}

.filter_options_container .filter_apply_btn {
  display: flex;
  justify-content: end;
  margin-top: 30px;
}

.filter_options_container .filter_apply_btn button {
  border-radius: 44px;
  background: var(--primary-violet);
  color: #fff;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px 12px;
  border: 1px solid var(--primary-violet);
}

.filter_options_container .filter_options_batches {
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px #cacaca;
  padding: 12px;
  height: 180px;
  overflow-y: auto;
}

.filter_options_container .filter_options_batch_item label {
  color: #5f5f5e;
  font-family: "DM Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.filter_options_container .filter_options_batch_item input {
  scale: 1.2;
}

.filter_options_container .filter_options_batch_item {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.filter_options_container .filter_batch_list {
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  gap: 10px;
  flex-wrap: wrap;
}

.filter_options_container .filter_batch_list .filter_batch_list_item {
  border-radius: 34px;
  color: #fff;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding: 8px 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  border: 1px solid #575757;
  color: #575757;
  cursor: pointer;
}

.formstudent_nav_container {
  background-color: #ffff;
  padding: 8px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0px;
}

.formstudent_nav_container .formstudent_nav_options {
  display: flex;
  align-items: center;
  gap: 30px;
}

.formstudent_nav_container .formstudent_nav_options a {
  font-family: "DM Sans";
  color: #848484;
  font-size: 24px;
  padding-bottom: 6px;
}

.formstudent_nav_container .formstudent_nav_options a.active {
  border-bottom: 3px solid var(--primary-violet);
  border-radius: 4px;
}

.formstudent_nav_container .formstudent_nav_btn button {
  background-color: #52bb38;
  border-radius: 8px;
  padding: 8px 12px;
  border: 1px solid #52bb38;
  color: #ffffff;
  cursor: pointer;
  font-size: 20px;
}

.formstudent_nav_container .formstudent_nav_btn .active {
  background-color: gray;
  border: 1px solid gray;
  cursor: not-allowed;
}

.formstudent_info_container .formstudent_info_left {
  background-color: #fff;
  padding: 14px;
}

.formstudent_info_container .formstudent_info_heading h2 {
  font-family: "DM Sans";
  font-size: 24px;
  font-weight: 500;
  line-height: 34.68px;
}

.formstudent_info_container .formstudent_info_heading {
  margin-bottom: 20px;
}

.formstudent_info_details .formstudent_info_detail_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.formstudent_info_details .formstudent_info_detail_item h3 {
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 500;
  line-height: 28.9px;
}

.formstudent_info_details .formstudent_info_detail_item h4 {
  box-shadow: 0px 0px 4.1px 0px #ededed;
  padding: 8px 12px;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.12px;
  color: #c1c1c1;
  width: 50%;
}

.formstudent_info_container .formstudent_info_bottom {
  background-color: #fff;
  margin-top: 8px;
  padding: 14px;
}

.formstudent_info_bottom .formstudent_info_bottom_heading h2 {
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 500;
  line-height: 34.68px;
  margin-bottom: 0;
}

.formstudent_info_bottom .formstudent_info_bottom_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formstudent_info_bottom .formstudent_info_bottom_heading svg {
  font-size: 24px;
  fill: #bcbcbc;
}

.formstudent_info_container .formstudent_info_bottom textarea {
  width: 100%;
  padding: 8px 12px;
  margin-top: 12px;
  border: 1px solid #f0f0f0;
  outline: none;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.12px;
  color: #c1c1c1;
}

.formstudent_info_container .formstudent_info_bottom .formstudent_info_bottom_svg {
  fill: #024c87;
  font-size: 34px;
}

.formstudent_info_container .formstudent_info_bottom_div {
  display: flex;
  align-items: start;
  gap: 14px;
}

.formstudent_info_container .formstudent_info_bottom_div button {
  border-radius: 50%;
  border: none;
}

.formstudent_header_container {
  background-color: #fff;
  padding: 8px 18px;
  display: flex;
  justify-content: space-between;
}

.formstudent_header_container .formstudent_header_left h2 {
  font-family: DM Sans;
  font-size: 32px;
  font-weight: 400;
  line-height: 46.24px;
  color: #8c8c8c;
  margin-bottom: 0;
}

.formstudent_header_container .formstudent_header_left h4 {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.12px;
  color: #8c8c8c;
}

.formstudent_info_container {
  background-color: #fff
}

.evidence_modal_container h3 {
  font-family: DM Sans;
  font-size: 32px;
  font-weight: 700;
  line-height: 41.66px;
  text-align: center;
  color: #2d2c2c;
}

.evidence_modal_container .evidence_modal_label {
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 26.04px;
  text-align: left;
  color: #576bad;
  margin-top: 10px;
  width: 100%;
}

.evidence_modal_container .evidence_modal_file {
  margin-top: 10px;
  border: 2px dashed #999999;
  width: 100%;
  padding: 22px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.evidence_modal_container .evidence_modal_file p {
  background-color: #3b8fee;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.83px;
  text-align: left;
  color: #ffff;
  border-radius: 37px;
  padding: 8px 22px;
  margin: 0;
}

.evidence_modal_container .evidence_modal_file span {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.83px;
  text-align: center;
  color: #999999;
}

.dispute-table-div .dispute-status button {
  padding: 6px 16px;
  gap: 16px;
  border-radius: 8px;
  background-color: #3b8fee;
  border: 1px solid #3b8fee;
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 500;
  line-height: 26.04px;
  text-align: center;
  color: #fff;
}

.dispute-table-div .lead-withdraw-btn {
  border: 1px solid #024c87;
  padding: 8px 22px;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.83px;
  text-align: center;
  border-radius: 8px;
  color: #024c87;
  background-color: transparent;
}

.dispute-table-div .dispute-status .dispute-status-won {
  background-color: #52bb38;
  border: 1px solid #52bb38;
}

.dispute-table-div .dispute-status .dispute-status-lost {
  background-color: #ce0101;
  border: 1px solid #ce0101;
}

.dispute-table-div .dispute-status .dispute-status-shared {
  background-color: #024c87;
  border: 1px solid #024c87;
}

.module_student_table .table_navlibk_td {
  color: var(--primary-blue);
  text-decoration: underline;
}

.module_student_table .lead_delete_icon {
  font-size: 20px;
  fill: red;
  cursor: pointer;
}

.loader_container {
  background-color: #024c87;
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tranparent_loader {
  background-color: #000;
  opacity: 0.5;
  z-index: 999;
  overflow: hidden;
}

.manager-evaluation-modal .manager-evaluation-modal-item-info h4 {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.83px;
  text-align: center;
}

.manager-evaluation-modal .manager-evaluation-modal-btn button {
  border: 1px solid #c9e2ff;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.83px;
  text-align: center;
  padding: 8px 12px;
  width: 100%;
  margin-top: 4px;
  border-radius: 8px;
}

.manager-evaluation-modal h2 {
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 500;
  line-height: 34.68px;
}

.manager-evaluation-modal .manager-evaluation-modal-item {
  padding: 12px 18px;
  background: #fbfbfb;
  margin-top: 16px;
  border-radius: 8px;
}

.manager-evaluation-modal-step2 .manager-evaluation-modal-step2-item {
  display: flex;
  align-items: center;
  gap: 10px;
  box-shadow: 0px 0px 4px 0px #d9d9d9;
  padding: 14px 18px;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 12px;
}

.manager-evaluation-modal-step2 .manager-evaluation-modal-step2-item svg {
  font-size: 28px;
  fill: #f6c358;
}

.manager-evaluation-modal-step2 .row {
  margin-top: 28px;
}

.manager-evaluation-modal-step2 .manager-evaluation-modal-step2-item h4 {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 24.33px;
  margin: 0;
}

.manager-evaluation-modal-step2 .manager-evaluation-modal-step2-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
}

.manager-evaluation-modal-step2 .manager-evaluation-modal-step2-info {
  display: flex;
  justify-content: space-between;
}

.manager-evaluation-modal-step2 .manager-evaluation-modal-step2-btn button {
  padding: 8px 20px;
  border-radius: 8px;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.83px;
  text-align: center;
  background: #024c87;
  border: none;
  color: #fff;
}

.manager-evaluation-modal-step2-info .manager-evaluation-modal-step2-options {
  box-shadow: 0px 0px 4px 0px #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 18px;
}

.manager-evaluation-modal-step2-info .manager-evaluation-modal-step2-option-div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.manager-evaluation-modal-step2-info .manager-evaluation-modal-step2-option-div h6 {
  margin: 0;
}

.manager-evaluation-modal-step2-info .manager-evaluation-modal-step2-option-item {
  display: flex;
  align-items: center;
}

.manager-evaluation-modal-step2-info .manager-evaluation-modal-step2-option-item label {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.23px;
}

.manager-evaluation-modal-step2-info .manager-evaluation-modal-step2-option-item {
  display: flex;
  align-items: center;
  margin-top: 6px;
  justify-content: space-between;
}

.module_student_table .lead-eval-table-td {
  color: #024c87;
  text-decoration: underline;
}

.formstudent_header_option .formstudent_header_select {
  box-shadow: 0px 0px 2.5px 0px #d1cfcf;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.formstudent_header_right .formstudent_header_option {
  position: relative;
}

.formstudent_header_option .formstudent_header_options {
  box-shadow: 0px 0px 4px 0px #0000001f;
  padding: 8px 12px;
  border-radius: 8px;
  position: absolute;
  top: 44px;
  background-color: #fff;
  left: 0;
  width: 100%;
}

.formstudent_header_right .formstudent_header_options_radio {
  display: flex;
  align-items: center;
  gap: 8px;
}

.formstudent_header_right .formstudent_header_options_radio label {
  cursor: pointer;
}

.ticket_chats_listitem_user_chat_detail {
  width: 80%;
}

.ticket_chats_box_container p {
  flex-direction: column;
}

.ticket_chats_box_container p b {
  margin-top: 2px;
  width: 100%;
  display: block;
  text-align: right;
}

.ticket_chats_list {
  height: 81vh;
  overflow-y: auto;
}

.ticket_chats_box .ticket_chats_box_container .ticket_chats_box_chatlist {
  height: 51vh;
  overflow-y: auto;
}

.scrollbar-hide::-webkit-scrollbar {
  width: 0;
}

.module_student_table .contact-us-button {
 background-color: transparent;
 border: none;
 text-decoration: underline;
 color: #024c87;
 margin-left:  20px ;
}

.contact_email_chats_content_profile_container {
  margin-top: 15px ;
}

.contact_email_chats_content_profile_container p {
  font-size: 16px ;
}


.contactus_email_chat_list_container .contactus_compose_email_btn {
  position: fixed;
  background-color: #024c87;
  padding: 8px 12px ;
  right: 2%;
  cursor: pointer;
  bottom: 10%;
  color: white;
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
  border-radius: 6px ;
  border: none;
  gap: 4px ;
}

.contactus_sendemail_modal .contactus_sendemail_modal_inp {
  margin-top: 10px ;
}

.contactus_sendemail_modal .contactus_sendemail_modal_inp label  {
  font-size: 16px;
  margin-bottom: 4px;
  width: 100%;
}

.contactus_sendemail_modal .contactus_sendemail_modal_inp input {
  width: 100%;
  outline: none;
  padding: 4px 8px ;
  border: 1px solid #ccc;
  border-radius: 4px ;
}

.contactus_sendemail_modal .contactus_sendemail_modal_submit {
  margin-top: 15px ;
  display: flex;
  justify-content: center;
  margin-bottom: 10px ;
}

.contactus_sendemail_modal .contactus_sendemail_modal_submit button {
  border: none;
  background-color: var(--primary-violet);
  color: var(--main-white);
  padding: 8px 22px ;
  border-radius: 6px;
  font-size: 18px ;
}

.email_chats_content .contact_email_chats_content {
  position: fixed;
  bottom: 0;
background-color: #fff;
  width: 82%;
 padding: 10px;
 padding-top: 18px;


}

.email_chats_content .contact_email_chats_content .contact_email_chats_content_btn {
  margin-top: 10px;
  display: flex;
  justify-content: end;
}

.email_chats_content .contact_email_chats_content .contact_email_chats_content_btn  button {
  background-color: var(--primary-violet);
  color: var(--main-white);
  font-size: 18px;
  border-radius: 6px;
  border: none;
  padding: 8px 12px ;
}


.contact_email_chats_content_profile_container {
  height: 466px;
  overflow-y: auto;
  margin-top: 85px;
}
/*  */

/* start */
/* end */