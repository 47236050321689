@media screen and (max-width: 1200px) {
  .header_profile_humberger {
    display: block;
  }

  .sidebar_container_cross {
    display: block;
    color: white;
    position: absolute;
    right: 7%;
    top: 1%;
    font-size: 25px;
  }

  .sidebar_container {
    position: absolute;
    top: 0;
    left: -100%;
    /* transform: translateX(-100%); */
    width: 300px;
    height: 100%;
    transition: transform 0.3s ease-out;
  }

  .sidebar_container.active {
    left: 0;
    /* transform: translateX(0); */
  }
  /*  */

  .new_sidebar_container {
    /* transform: translateX(-100%); */
    /* left: ; */
    position: fixed;
    top: 0;
    width: 300px;
    left: -300px;
    transition: left 0.5s ease-in-out;
    display: none;
  }

  .new_sidebar_container.active {
    /* transform: translateX(0); */
    display: block;
    z-index: 99;
    left: 0;
  }

  .new_sidebar_container .new_sidebar_cross {
    display: block;
  }

  .new_sidebar_container .new_sidebar_logout {
    position: absolute;
    bottom: 3%;
  }
}

@media screen and (max-width: 990px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 576px) {
  .display-sm-none {
    display: none;
  }
  .filter_options_container {
    width: 100%;
    top: 23%;
  }

  .form_table_nav_search {
    justify-content: space-between;
  }

  .form_table_nav_search .form_table_nav_input {
    width: 70%;
  }
}
@media screen and (max-width: 426px) {
  .form_table_nav_search {
    flex-direction: column;
  }

  .form_table_nav_search .form_table_nav_input {
    width: 100%;
  }

  .header_container {
    flex-direction: column-reverse;
    gap: 20px;
    align-items: end;
  }
}

@media screen and (min-width: 1550px) {
  .offer_letter_table_div table tbody td {
    font-size: 16px;
  }
  .table-template-style tbody tr td {
    font-size: 16px;
  }
}
