.calc-parent {
  padding: 20px;
  background: #F4F4F4;
}

.calc-parent .calc-main-heading {
  font-size: 22px;
  font-weight: 300;
  font-family: "Inter";
}

.calc-parent .calc-card {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 56%;
}

.calc-card .upper-tabber-tray {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.calc-card .upper-tabber-tray .tabber-calc-item {
  padding-block: 10px;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.calc-card .upper-tabber-tray .tabber-calc-item::before {
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 4px;
  background: transparent;
  bottom: 0;
  left: 0;
}

.calc-card .upper-tabber-tray .tabber-calc-item.calc-tabber-active {
  font-weight: 500;
  background: #f7f8fe;
}
.calc-card .upper-tabber-tray .tabber-calc-item.calc-tabber-active::before {
  background: linear-gradient(to right, #FE6C5F, #3292F1);
}


.calc-card .calc-content {
  padding: 15px;
  
}

.calc-card .calc-content .installment-content-parent {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
}

.installment-content-parent .installment-form {
  /* border-right: 1px solid rgba(0, 0, 0, 0.26); */
}

.installment-form .installment-form-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 30px;
  margin-block: 20px;
}

.installment-form .installment-form-item p {
  margin: 0;
}
.installment-form .installment-form-item input {
  padding: 6px 10px;
  outline: none;
}

.installment-form-item .installment-type-tray {
  display: flex;
  align-items: center;
  gap: 24px;
}

.installment-form .installment-form-item input[type="radio"] {
  width: 17px;
  height: 17px;
}

.installment-form .installment-form-item label {
  margin: 0;
}

.installment-form .installment-form-item .radio-wr {
  display: flex;
  align-items: center;
  gap: 6px;
}

.installment-form .installment-form-item select {
  height: auto;
  color: #000;
  font-size: 14px;
  appearance: none;
}
.installment-form .installment-form-item .select-wr {
  position: relative;
}
.installment-form .installment-form-item .select-wr::after{
  content: "";
  position: absolute;
  display: block;
  width: 15px;
  height: 15px;
  top: 12px;
  right: 12px;
  background-image: url(/public/oeson-select-custom-arr.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}



.installment-form .installment-form-item .suggestive-inp-wr {
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  position: relative;
}

.installment-form .installment-form-item .suggestive-inp-wr .suggestion-holder {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  max-height: 300px;
  overflow-y: auto;
}

.installment-form .installment-form-item .suggestive-inp-wr .suggestion-holder .suggestion-item {
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  color: #119dde;
  width: 100%;
  transition: all 300ms ease;
  cursor: pointer;
}

.installment-form .installment-form-item .suggestive-inp-wr .suggestion-holder .suggestion-item:hover {
  background: #f7f8fe;
}

.installment-form-item .curr-item-smcard {
  padding: 6px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  cursor: pointer;
}

.suggestive-inp-wr input::placeholder {
  color: #000;
}

.curr-item-smcard-active {
  background: #f7f8fe;
  background-image: url(/public/curr-check.png);
  background-size: 32px 32px;
  background-repeat: no-repeat;
  background-position: top 10px right 10px;
}

.installment-form .installment-form-item .calc-main-btn {
  line-height: 40px;
  border: none;
  outline: none;
  background: #343A40;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.installment-form .installment-form-item .calc-sec-btn {
  line-height: 40px;
  border: none;
  outline: none;
  background: #e1e9f0;
  color: #000;
  font-weight: 500;
  font-size: 16px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

/*  */
.installment-result {
  border: 1px solid gray;
  position: relative;
}


.installment-result .installment-result-head {
  text-align: center;
  font-size: 14px;
  padding: 6px 0px;
  background: #119dde;
  color: #fff;
  margin: 0;
}

.installment-result .installment-dt-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  padding-block: 8px;
  padding-inline: 10px;
  border-bottom: 1px dashed gray;
}

.installment-result .installment-dt-row p {
  margin: 0;
  
}

.installment-result .installment-dt-row.grand-total {
  padding-block: 7px;
  background: #f7f8fe;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-inline: 10px;
  border-bottom: 1px dashed gray;
}
.grand-btm .installment-dt-row.grand-total:last-child{
  border-bottom: none;
}
.grand-btm .installment-dt-row.grand-total:nth-child(1){
  border-top: 1px dashed gray;
}

.grand-btm {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.onetimepay-row {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 4px 10px;
  border-bottom: 1px dashed gray;
  transition: all 300ms ease;
  cursor: pointer;
}

.onetimepay-row:hover {
  background: aliceblue;
}

.onetimepay-row span {
  display: inline-block;
}

.price-copy-alert {
  padding: 4px 10px;
  background: #119dde;
  margin-block: 8px;
  color: #fff;
  font-weight: 500;
}