@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.dashboard-parent {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.dashboard-leaderboard-wr {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(/public/RectLight.svg);
  background-position: bottom -40px center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 0;
  background-color: #343A40;
}

.leaderboard-btm-strip {
  height: 10px;
  background: linear-gradient(to right, #FE6C5F, #3292F1);
  width: 100%;
}

.dashboard-leaderboard-wr::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  display: block;
  /* background: url(/public/topography.svg); */
  background-position: bottom center;
}


.dashboard-leaderboard-wr .main-ranking-table {
  display: flex;
  align-items: flex-end;
  gap: 0px;
}

.dashboard-leaderboard-wr .main-ranking-table .rank-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.dashboard-leaderboard-wr .main-ranking-table .rank-2-card,
.dashboard-leaderboard-wr .main-ranking-table .rank-3-card {
  padding: 0px 50px;
  position: relative;
  z-index: 0;
}

@keyframes rotateimgpulse {
  from {
    transform: rotate(0deg);
    scale: 0.5;
  }
  to {
    transform: rotate(360deg);
    scale: 1;
  }
}

.rank-3-card-star{
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background-image: url(/public/starshine.png);
  background-size: contain;
  background-position: center center;
  z-index: 1;
  /* animation:rotateimgpulse 7s linear infinite alternate; */
}
.rank-2-card-star{
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  background-image: url(/public/starshine.png);
  background-size: contain;
  background-position: center center;
  z-index: 1;
  /* animation:rotateimgpulse 7s linear infinite reverse alternate; */
}
 
.dashboard-leaderboard-wr .main-ranking-table .rank-1-card img {
  width: 140px;
  height: 155px;
  border-radius: 50%;
  border: 6px solid #f0a016;
}

.dashboard-leaderboard-wr .main-ranking-table .rank-2-card img,
.dashboard-leaderboard-wr .main-ranking-table .rank-3-card img {
  width: 130px;
  height: 145px;
  border-radius: 50%;
  border: 6px solid #fff;
}

.dashboard-leaderboard-wr .main-ranking-table .rank-2-card h4,
.dashboard-leaderboard-wr .main-ranking-table .rank-3-card h4 {
  font-size: 22px;
  font-weight: 600;
  line-height: 42px;
  color: #fff;
  margin: 0;
  margin-bottom: 15px;
  font-family: "Poppins";
  text-align: center;
  margin-top: 15px;
}

.dashboard-leaderboard-wr .main-ranking-table .rank-2-card p,
.dashboard-leaderboard-wr .main-ranking-table .rank-3-card p {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #91b0ff;
  text-transform: uppercase;
}

.dashboard-leaderboard-wr .main-ranking-table .rank-1-card h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 46px;
  color: #fff;
  margin: 0;
  margin-bottom: 15px;
  font-family: "Poppins";
}

.dashboard-leaderboard-wr .main-ranking-table .rank-1-card p {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #f0a016;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.golden-leaf-wr {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 52px;
  background-image: url(/public/goldenleaf.png);
  background-position: bottom center;
  background-size: contain;
  background-repeat: no-repeat;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.slate-icons-new {
    color: rgb(180, 182, 188) !important;
}

.slate-icons-new.new-activ-icn {
    color: #fff !important;
}

.sidebar-top-logo {
    display: block;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 20px;
}

.sidebar-top-logo img {
    width: 100px;
}

.sidebar-new-ul {
    padding: 0px;
    list-style: none;
}

.sidebar-new-ul li {
    height: 31px;
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    line-height: 24px;
    color: rgb(148, 152, 163);
    position: relative;
    font-size: 14px;
    margin-block: 0px;
    transition: all 400ms ease;
}

.sidebar-new-ul li:hover {
    background: rgba(255, 255, 255, 0.1);
}


.sidebar-new-ul li a {
    text-decoration: none;
    color: rgb(230, 233, 239);
    font-family: Inter, "Inter Fallback Arial", Arial;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    text-decoration-line: none;
    line-height: 1.25rem;
    letter-spacing: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    height: 100%;
    padding: 0px 12px 0px 16px;
}

.sidebar-new-ul li .link-active-new-sidebar {
    position: relative;
    font-weight: 400;
    color: #fff;
}

.sidebar-new-ul li .link-active-new-sidebar:not(.nono)::before {
    position: absolute;
    content: "";
    display: block;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: #fff;
}

.sidebar-new-category-section-heading {
    font-size: 11px;
    font-weight: 600;
    font-style: normal;
    line-height: 22px;
    color: rgb(223, 224, 226);
    text-transform: uppercase;
    padding: 4px 12px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 400ms ease;
    cursor: pointer;
}

.sidebar-new-category-section-heading:hover {
    background: rgba(255, 255, 255, 0.1);
}

.sidebar-new-section-separator {
    border: 0.5px solid rgb(69, 75, 100);
    flex: 0 0 auto;
    order: 3;
    align-self: stretch;
    -webkit-box-flex: 0;
    margin: 18px 0px;
}

.new-sidebar-main-container {
    flex: 1;
    overflow: hidden auto;
    width: 100%;
    position: relative;
    padding-bottom: 0px;
    align-items: unset !important;
    flex-direction: unset;
    min-height: auto !important;
}

.new-sidebar-main-container::after {
    content: "";
    display: block;
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 68px;
    background: linear-gradient(to bottom, transparent, #0a0a29);
}


.new-sidebar-main-container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.new-sidebar-main-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.sidebar-new-admin-footer {
    font-size: 12px;
    color: rgb(160, 166, 185);
    margin: 0;
    line-height: 21px;
    text-align: center;
}

.admin-badge-top {
    background: linear-gradient(to bottom right, #315BC1, #315BC1);
    padding: 12px 12px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 5px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.admin-badge-top::before {
    position: absolute;
    content: "";
    bottom: -2px;
    display: block;
    width: 100%;
    height: 4px;
    background: linear-gradient(-60deg, #fff, #fff 40%, #f7aa3e 40%, #f7aa3e 70%, #fff 70%);
    background-size: 400%;
    left: 0;
    animation: movebg 4000ms linear infinite forwards;
}

@keyframes movebg {
    from {
        background-position: right;
    }

    to {
        background-position: left;
    }
}


.link-active-new-sidebar {
    background-color: #000;
}

.new-sidebar-list-bulb {
    height: 100%;
    width: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.new-sidebar-list-bulb .bulb {
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 1px solid rgb(128, 139, 174);
    position: relative;
}

.new-sidebar-list-bulb .line {
    display: block;
    flex: 1;
    width: 1px;
    background: rgb(113, 121, 150);
}


.link-active-new-sidebar .new-sidebar-list-bulb .bulb {
    background: #91b0ff;
    box-shadow: 0px 0px 8px 4px #457aff;
    border: 1px solid #457aff;
    animation: bulbglow 300ms ease-in-out;
}

@keyframes bulbglow {
    from {
        background: transparent;
        border-color: rgb(128, 139, 174);
        box-shadow: none;
    }

    to {
        background: #91b0ff;
        border-color: #457aff;
        box-shadow: 0px 0px 8px 4px #457aff;
    }
}

.dashboard-new-header {
    padding-block: 20px;
}

.dashboard-new-container {
    background-color: #F0F3F4;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.dashboard-new-container .upper-stats-tray {
    display: grid;
    width: 100%;
    background-color: #fff;
    color: #58666e;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    position: relative;
    z-index: 0;
    overflow: hidden;
}

/* .upper-stats-tray::before {
    content: "";
    display: block;
    width: 350px;
    height: fit-content;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(/public/traytop_dash.png);
    height: 70px;
    background-position: -100px -30px;
    background-repeat: no-repeat;
    z-index: -1;
} */
.upper-stats-tray::after {
    content: "";
    display: block;
    width: 392px;
    height: fit-content;
    position: absolute;
    bottom: -10px;
    right: 0px;
    background-image: url(/public/traybottom_dash.png);
    height: 70px;
    background-repeat: no-repeat;
    z-index: 1;
    transform: rotateY(180deg);
}

.upper-stats-tray .upper-stats-heading {
    grid-column: span 4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid rgb(203, 213, 226);
}

.tray-btn-separator {
    width: 1px;
    background-color: rgb(203, 213, 226);
    height: 100%;
}

.upper-stats-heading p {
    font-family: "Inter";
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    margin: 0px;
    display: flex;
    align-items: center;
    gap: 11px;
}

.upper-stats-heading .overview-last-update {
    display: flex;
    font-size: 12px;
    font-weight: 400;
    padding-right: 12px;
    color: rgba(46, 91, 255, 0.32);
    -webkit-box-align: center;
    align-items: center;
    gap: 4px;
}

.upper-stats-heading .overview-refresh-btn {
    color: rgb(41, 80, 219);
    font-family: Inter, "Inter Fallback Arial", Arial;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    text-decoration-line: none;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    margin: 0px;
    padding: 0px;
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    gap: 4px;
}

.upper-stats-tray .stat-card {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 16px 24px;
}

.upper-stats-tray .stat-card:not(:last-child) {
    border-right: 1px solid rgb(203, 213, 226);
}

.stat-card .stat-heading,
.stat-bottom-comment {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.stat-card .status-badge-success {
    display: inline-block;
    padding: 1px 4px;
    background: #E8F7EF;
    color: rgb(0, 138, 69);
    font-family: "Inter";
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    border-radius: 10px;
    margin-bottom: 5px;
    overflow-wrap: break-word;
}

.stat-bottom-comment {
    font-size: 13px;
    color: rgb(118, 142, 167);
    margin-top: 10px;
}

.stat-card h5 {
    font-size: 24px;
    font-weight: 400;
    color: rgb(64, 86, 109);
    display: flex;
    align-items: flex-end;
}

.stat-card .after-decimal-statval {
    font-size: 85.71%;
    opacity: 0.7;
}

.stat-card .stat-currency {
    opacity: 0.7;
    margin-right: 10px;
}

.dashboard-graph-tabber-container {
    background-color: #fff;
}

.dashboard-graph-tabber-container .dashboard-graph-tabber-heading {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(203, 213, 226);
    gap: 0px;
    background: #FBFCFD;
}

.dashboard-graph-tabber-container .dashboard-graph-tabber-heading p {
    padding: 12px 4px;
    margin: 0px;
    color: rgb(118, 142, 167);
    border-bottom: 3px solid transparent;
    bottom: -1px;
    display: inline-block;
    font-weight: 600;
    margin: 0 16px;
    position: relative;
    font-size: 15px;
}

.dashboard-graph-tabber-container .dashboard-graph-tabber-heading p.active {
    padding: 12px 4px;
    margin: 0px;
    border-color: rgb(41, 80, 219);
    color: rgb(41, 80, 219);
    border-bottom: 3px solid;
    bottom: -1px;
    display: inline-block;
    font-weight: 600;
    margin: 0 16px;
    position: relative;
    font-size: 15px;
}

.graph-inner-heading {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 24px;
    border-bottom: 1px solid rgb(113, 121, 150);
}

.dashboard-graph-tabber-content .graph-inner {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.graph-inner .no-data-found-heading {
    color: rgb(137, 145, 174);
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    font-family: Inter;
    display: flex;
    align-items: center;
    gap: 5px;
}

.graph-inner .no-data-found-content {
    color: rgb(111, 117, 138);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: Inter;
    margin: 0px;
}

.dashboard-graph-tabber-container .dashboard-graph-tabber-content {
    padding: 32px 24px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
}

.graph-button-tray {
    border: 1px solid rgb(113, 121, 150);
    flex: 1;
}

.graph-inner-heading .graph-inner-heading-left .graph-title-main {
    font-family: "Inter";
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    margin: 0px;
    display: flex;
    align-items: center;
    gap: 11px;
}

.graph-inner-heading .graph-inner-heading-left .graph-title-secondary {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0px;
}

.graph-inner-heading .graph-inner-heading-right {
    display: flex;
    align-items: stretch;
}

.graph-inner-heading .graph-inner-heading-right button {
    width: 78px;
    padding: 9px 10px;
    background-color: #fff;
    border-color: #cfdadd;
    border: 1px solid;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    margin-bottom: 0;
    text-align: center;
    touch-action: manipulation;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    color: #58666e;
}

.graph-inner-heading .graph-inner-heading-right button.active {
    border: 1px solid;
    border-color: #528ff0;
    background-color: #f7f8fe;
}

.graph-inner-heading .graph-inner-heading-right button:nth-child(2):not(:last-child):not(button[type="submit"]):not(.thanos-btn) {
    border-left: none;
    border-right: none;
}

.live-badge-dashboard {
    font-size: 14px;
    color: red;
}

.live-badge-dashboard .live-rot-icon {
    animation: rotateicn 4s linear infinite forwards;
}

@keyframes rotateicn {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}

.lead-status-badge {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    color: orange;
    padding: 1px 2px;
    background-color: #ff80001a;
}

.dash-analyt-select-lead {
    padding-inline: 20px;
}


select.dash-analyt-select-lead {
    background-color: transparent;
    color: rgb(113, 121, 150);
    font-size: 14px;
    font-weight: 400;
    background-position: 20px;
}

.transaction-table {
    width: 100%;
}

.transaction-table tr:nth-of-type(2n+1){
    background-color: #fbfcfec5;
}

.transaction-table tr {
    transition: all 300ms ease;
}
.transaction-table tr:hover {
    background-color: aliceblue;
}
.transaction-table th {
    padding: 2px 12px;
    font-family: Inter;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    color: rgb(64, 86, 109);
}

.transaction-table thead {
    border-bottom: 1px solid;
}

.transaction-table td {
    padding: 4px 12px;
    font-family: Inter;
    font-size: 14.5px;
    font-weight: 400;
    line-height: 24px;
    text-transform: capitalize;
    color: rgb(88, 102, 110);
}
.transaction-table td button {
    background: none;
    border: none;
    outline: none;
    color: rgb(82, 143, 240);
    font-weight: 600;
}
.graph-inner-heading-right .transaction-search-form {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    margin-right: 20px;
}
.graph-inner-heading-right .transaction-search-form input {
    padding: 4px 10px;
}

.transaction-info-container {
    width: 100%;
}

.txn-info-title-main {
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    margin: 0px;
    display: flex;
    align-items: center;
    gap: 11px;
}


.txn-info-back {
    color: rgb(41, 80, 219);
    margin-bottom: 10px;
    width: fit-content;
    padding: 3px 10px 3px 0px;
    cursor: pointer;
}

.txn-blocks-holder {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 25px;
    row-gap: 0px;
}

.txn-user-info {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(46, 91, 255, 0.32);
    grid-column: span 2;
    box-shadow: 0px 0px 2px 1px rgba(0,0,0, 0.12);
}

.txn-user-info h5 {
    border-bottom: 1px solid;
    padding-bottom: 5px;
    padding: 4px 12px;
    background: #f7f8fe;
}

.txn-user-info .thanos-h5 {
    border-top: 1px solid;
}

.txn-user-info p {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0px;
    padding: 0px 12px;
    white-space: nowrap;
}

.txn-user-info p span {
    display: inline-block;
}

.nested-cohort-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
}


.payable-p {
    border-top: 1px solid;
    font-weight: 500;
    background-color: aliceblue;
}

.txnbilling p span:nth-child(2) {
    text-align: end;
}

.txnbilling p {
    border-bottom: 1px solid;
    font-size: 15px;
}

.txnbilling h5 {
    margin: 0;
}

.txnbilling .txn-discount {
    font-weight: 500;
    font-size: 13px;
    text-align: end;
}

.txnbilling p span:first-child {
    border-right: 1px solid;
}
.txn-discount.discount-sep {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.txn-discount.discount-sep span:nth-child(1) {
    border: none;
    padding-left: 10px;
}

.claimed-by-conflict {
    font-size: 14px;
    margin-top: 10px;

}

.conflict-flasher {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    position: relative;
    margin-block: 8px;
    padding: 10px 20px;
    overflow: hidden;
}

.conflict-flasher .flasher-ribbon {
    width: 160px;
    position: absolute;
    top: 0;
    left: -30px;
    bottom: 0;
    transform: skew(-45deg);
    background: linear-gradient(to right, #f18a0a,  #ffc300);
}

.conflict-flasher .flasher-ribbon-2 {
    width: 145px;
    position: absolute;
    top: 0;
    left: -30px;
    bottom: 0;
    transform: skew(45deg);
    background: linear-gradient(to right, #f18a0a4a,  #ffc3004a);
}

.conflict-flasher p {
    margin: 0;
    padding-left: 130px;
    font-weight: 600;
}

.leaderboard-heading-blaze {
    background: linear-gradient(to right, #D1913C, #FFD194, #eea849, #FFD194, #D1913C);
    padding-top: 25px;
    padding-bottom: 0px;
    background-clip: text;
    color: transparent;
    font-weight: 700;
    font-family: "Inter";
    font-size: 22px;
    background-size: 600%;
    animation: movetxtbg 5s ease infinite forwards alternate-reverse;
}

@keyframes movetxtbg {
    from {
        background-position: left center;
    }
    to {
        background-position: right center;
    }
}

.rank-card .rank-img-holder {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rank-card .rank-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 700;
    padding-bottom: 0px;
    background: linear-gradient(to bottom right, #373B44, 
    #4286f4);
    overflow: hidden;
    padding: 8px 16px;
    position: relative;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.rank-badge::before {
    content: "";
    position: absolute;
    top: 0;
    left: -150%;
    width: 200%;
    height: 100%;
    border-radius: 2%;
    background: linear-gradient(120deg, transparent, hsla(0, 0%, 100%, .4), transparent);
    transform: skewX(140deg);
    animation: shine 8s infinite;
}
@keyframes shine {
    0% {
        left: -600%;
    }
    10% {
        left: -600%;
    }
    50% {
        left: 600%;
    }
    100% {
        left: -600%;
    }
}


.rank-card .rank-badge.rank-badge-1::before {
    background: linear-gradient(120deg, transparent, #ffffff3a, transparent);
}