.header_profile_humberger {
  display: none;
}

.sidebar_container_cross {
  display: none;
}

.filter_options_container {
  width: 450px;
  /* padding-bottom: 20px; */
  height: 95%;
  /* top: 1%; */
}

.filter_options_container .filter_apply_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  padding: 0px 10px;
}

.filter_options_container .filter_apply_btn button:first-child {
  background-color: gray;
  border: 0;
  outline: 0;
}

/* Sidebar.js FormTableNavs.jS Header.js */

/* resources start */
.resources_nav {
  padding: 20px 40px 0 30px;
  background-color: white;
}

.resources_nav_header {
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.resources_nav span {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  color: rgba(45, 44, 44, 1);
}

.resources_nav .resources_nav_input {
  /* box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  width: 311px;
  height: 44px;
  border-radius: 8px;
  position: relative; */
  font-size: 16px;
  width: 300px;
  outline: none;
  padding: 0 28px;
  border-radius: 6px;
  border: 1px solid rgba(217, 217, 217, 1);
  /* font-size: 14px; */
  font-weight: 400;
  color: rgba(154, 163, 171, 1);
  position: relative;
}

.resources_nav_input .resources_nav_search_icon {
  position: absolute;
  font-size: 20px;
  top: 10px;
  left: 12px;
}

.resources_nav_input input {
  width: 100%;
  padding: 8px;
  border: none;
  outline: none;
}

.resources_nav .resources_nav_tabs {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 30px;
  padding-left: 30px;
  padding-top: 20px;
}

.resources_nav_tabs li {
  font-size: 20px;
  font-weight: 400;
  color: rgba(132, 132, 132, 1);
  padding-bottom: 20px;
}

.resources_nav_tabs li.active {
  color: rgba(72, 72, 72, 1);
  font-weight: 500;
  border-bottom: 6px solid rgba(2, 76, 135, 1);
}

.resources_nav_tabs label {
  background: transparent;
  outline: 0;
  width: 114px;
  height: 42px;
  border: 1px solid rgba(2, 76, 135, 1);
  font-size: 14px;
  font-weight: 400;
  border-radius: 50px;
  color: rgba(2, 76, 135, 1);
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resources_file_container {
  padding: 30px;
}

.resources_file_container .resources_file_div {
  display: flex;
}

.resources_file_container .resources_file_box {
  width: 245px;
  height: 297px;
  background-color: white;
  border-radius: 8px;
  padding: 22px 16px;
}

.resources_file_active .resources_file_upload {
  background-color: rgba(233, 240, 246, 1);
  border: 2px dashed rgba(0, 142, 255, 1);
  width: 100%;
  height: 152px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.resources_file_upload .resources_file_upload_icon {
  font-size: 50px;
}

/* resources end */

.module_card_container {
  padding: 20px 54px;
  /* height: 79vh; */
}

.module_card_container .module_card_item {
  border-radius: 8px;
  background: var(--main-white);
  padding: 20px;
  margin-bottom: 12px;
  height: 297px;
  cursor: pointer;
}
.module_card_container .row {
  height: 67vh;
  overflow-y: auto;
}
.module_card_container .module_card_create .module_card_upload_img {
  width: 213px;
  border-radius: 8px;
  height: 163px;
  margin: auto;
  display: block;
}

.module_card_item .module_card_item_file_box {
  width: 213px;
  height: 163px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.module_card_container .module_card_item img {
  width: 82px;
  height: 82px;
  border-radius: 8px;
  /* height: 169px; */
}

.module_card_container .module_card_item svg {
  font-size: 28px;
  fill: #e30e00;
  cursor: pointer;
}

.module_card_container .module_card_item h4 {
  color: rgba(45, 44, 44, 1);
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 14px;
  margin-bottom: 16px;
}
.module_card_container .module_card_item h5 {
  color: var(--primary-white);
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 6px;
}
.module_card_container .module_card_item h6 {
  color: var(--light-gray);
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 6px;
}
.module_card_container .module_card_item button {
  width: 100%;
  /* margin-top: 14px; */
  border-radius: 7px;
  border: 1px solid #c9e2ff;
  padding: 8px 22px;
  color: #0057ff;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  background-color: var(--main-white);
}

.module_card_container .module_card_item button:hover {
  border: 1px solid #0057ff;
  background-color: #0057ff;
  color: var(--main-white);
}

.module_card_container .module_card_create button {
  background-color: var(--primary-violet);
  border: 1px solid var(--primary-violet);
  color: var(--main-white);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  fill: var(--main-white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.module_card_container .module_card_create button svg {
  fill: inherit;
  font-size: 22px;
}

.module_card_container .module_card_create button:hover {
  border: 1px solid var(--primary-violet);
  color: var(--primary-violet);
  background-color: var(--main-white);
  fill: var(--primary-violet);
}

/*  */

.resources_upload_container {
  padding: 20px 30px;
}

.resources_upload_container .resources_upload_tab {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  gap: 50px;
}

.resources_upload_container .resources_upload_tab div {
  margin-left: 10px;
}

.resources_upload_tab input {
  border: 2.38px solid var(--primary-body-text, rgba(92, 92, 92, 1));
  height: 20px;
  width: 20px;
  margin-left: 24px;
}

.resources_upload_tab label {
  width: 15%;
  text-align: center;
}

.resources_upload_tab_body {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-top: 20px;
  background-color: white;
  padding: 10px 0;
  border-radius: 8px;
}

.resources_upload_tab_body span,
.resources_upload_tab_body a {
  width: 15%;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.resources_upload_tab_body .resources_date {
  color: #8c8c8c;
}
/* start */

.resources_upload_tab_body .resources_edit_btn {
  color: rgba(2, 76, 135, 1);
  position: relative;
  cursor: pointer;
}

.resources_upload_tab_body .resources_edit_btn .resources_dropdown {
  position: absolute;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: white;
  padding: 10px 10px;
  gap: 4px;
  border-radius: 5px;
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  top: 27px;
  left: -100px;
}

.resources_upload_tab_body .resources_edit_btn .resources_dropdown button {
  background-color: transparent;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  gap: 9px;
  font-size: 15px;
}

.resources_upload_tab_body
  .resources_edit_btn
  .resources_dropdown
  button:first-child {
  color: #576bad;
}
.resources_upload_tab_body
  .resources_edit_btn
  .resources_dropdown
  button:last-child {
  color: #e30e00;
}

.resources_upload_tab_body .serial_no {
  font-size: 20px;
  margin-left: 24px;
  width: 1%;
}

/* end */
.resources_upload_tab_body a:hover {
  text-decoration: underline;
}

.resources_upload_tab_body input {
  border: 2.38px solid var(--primary-body-text, rgba(92, 92, 92, 1));
  height: 20px;
  width: 20px;
  /* margin-left: 20px; */
}

.resources_upload_tab_body img {
  width: 52px;
  height: 52px;
}

.resources_upload_tab_body span .delete_icon {
  color: rgba(196, 7, 7, 1);
  font-size: 30px;
}

.create-module-modal .create-module-modal-body {
  padding: 20px;
}
.create-module-modal .create-module-modal-body h2 {
  color: var(--primary-blue);
  text-align: center;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.create-module-modal .create-module-modal-body label {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  margin-bottom: 8px;
  margin-top: 32px;
}

.create-module-modal .create-module-modal-body input {
  border-radius: 5px;
  border: 1px solid var(--primary-white);
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  outline: none;
}

.create-module-modal .create-module-modal-body button {
  background: #6aac29;
  border-radius: 52px;
  border: 1px solid;
  padding: 10px 29px;
  color: var(--main-white);
  margin-top: 42px;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.bootstrap-modal-custom .modal-close-btn {
  position: absolute;
  right: 2%;
  top: 2%;
  fill: #a80000;
  font-size: 26px;
  cursor: pointer;
}
/* .resources_upload_tab .resources_type {
  width: 20%;
  text-align: center;
}a

.resources_upload_tab .resources_name {
  width: 15%;
  text-align: left;
}

.resources_upload_tab .resources_date{
  width: 15%;

}

.table{
  background-color: transparent;
} */

/* newsidebar */
.new_sidebar_col {
  padding-right: 0;
  z-index: 9;
}

.new_sidebar_container {
  position: fixed;
  background-color: #343A40;
  height: 100vh;
  /* position: relative; */
  overflow-y: auto;
  width: inherit;
  /* width: 250px; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.new_sidebar_container .new_sidebar_logo img {
  width: 124px;
  height: 36px;
}

.new_sidebar_container h4 {
  color: rgba(131, 137, 159, 1);
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.new_sidebar_container .new_sidebar_home_list {
  margin-top: 30px;
  padding-left: 10px;
  position: relative;
}

.new_sidebar_home_list .new_sidebar_home_listitem {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-top: 20px;
}

.new_sidebar_home_list .new_sidebar_home_listitem.active {
  background-color: rgba(102, 112, 133, 0.4);
  padding: 12px 16px;
  margin-left: -14px;
  border-radius: 12px;
  margin-bottom: 20px;
  transition: 0.2s;
}

.new_sidebar_home_list .new_sidebar_home_listitem.active p {
  color: rgba(59, 143, 238, 1);
}

.new_sidebar_home_list .new_sidebar_home_listitem.active svg path {
  /* fill: #0057ff; */
  fill: rgba(59, 143, 238, 1);
  /* background-color: yellow; */
}

.new_sidebar_home_list .new_sidebar_home_listitem.active svg .enroll-stroke {
  stroke: rgba(59, 143, 238, 1);
}

.new_sidebar_home_list .new_sidebar_home_listitem.active svg rect {
  stroke: rgba(59, 143, 238, 1);
}

.new_sidebar_home_list
  .new_sidebar_home_listitem.active
  .new_sidebar_home_listitem_downicon {
  color: rgba(59, 143, 238, 1);
}

.new_sidebar_home_list
  .new_sidebar_home_listitem.active
  .new_sidebar_home_listitem
  img {
  color: #0057ff;
}

.new_sidebar_home_listitem .new_sidebar_home_listitem_downicon {
  margin-left: auto;
  color: white;
  transition: 0.4s;
}

.new_sidebar_home_listitem img {
  width: 18px;
}

.new_sidebar_customer_list img {
  width: 24px;
}

.new_sidebar_home_listitem p {
  color: white;
  margin: 0;
  font-size: 16px;
  margin-top: 4px;
}

.new_sidebar_home_listitem_lead {
  position: relative;
}
.new_slider_home_listitem_dropdown {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  animation: open 0.6s forwards;
}

.new_slider_home_listitem_dropdown .new_slider_home_listitem_dropdownitem {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 5px;
  transition: 0.3s;
  position: relative;
  cursor: pointer;
}

.new_slider_home_listitem_dropdown
  .new_slider_home_listitem_dropdownitem.active
  > .dot {
  background-color: rgba(59, 143, 238, 1);
}
.new_slider_home_listitem_dropdown
  .new_slider_home_listitem_dropdownitem.active
  p {
  color: rgba(59, 143, 238, 1);
}

.new_slider_home_listitem_dropdown .new_slider_home_listitem_dropdownitem .dot {
  width: 8px;
  border-radius: 50px;
  height: 8px;
  background-color: rgba(77, 84, 107, 1);
  position: relative;
}

.new_slider_home_listitem_dropdownitem .dot::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 27px;
  background: rgba(77, 84, 107, 1);
  left: 50%;
  transform: translateX(-50%);
  top: 11px;
}

.new_slider_home_listitem_dropdownitem .lastdiv::after {
  content: none;
}

.new_slider_home_listitem_dropdown .new_slider_home_listitem_dropdownitem p {
  margin: 0;
  font-size: 14px;
}

.new_slider_home_listitem_dropdownitem .hover_star {
  margin-left: auto;
  margin-right: 16px;
  font-size: 16px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.new_slider_home_listitem_dropdownitem .select_star {
  margin-left: auto;
  margin-right: 16px;
  transition: all 0.3s;
  font-size: 16px;
}

.new_slider_home_listitem_dropdownitem:hover .hover_star {
  opacity: 1;
}

.new_slider_home_listitem_dropdownitem .favitem_star {
  margin-left: auto;
  margin-right: 16px;
}

.new_sidebar_home_listitem_favitem {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  margin-top: 10px;
  gap: 8px;
  padding-left: 27px;
}

.new_sidebar_home_listitem_favitem .new_sidebar_home_listitem_favitem_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.7);
}

.new_sidebar_home_listitem_favitem p {
  color: rgba(59, 143, 238, 1);
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  margin: 0;
}

.new_sidebar_home_listitem_favitem .favitem_star {
  width: 14px;
  cursor: pointer;
  /* color: rgba(59, 143, 238, 1); */
  margin-right: 16px;
  /* height: 14px; */
}

@keyframes open {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/*  */

.new_sidebar_container .new_sidebar_logout {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 10px;
  color: white;
  margin-top: 20px;
  cursor: pointer;
  padding-top: 20px;
  border-top: 1px solid #313843;
}

.new_sidebar_logout img {
  width: 20px;
}

.new_sidebar_logout p {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.new_sidebar_container .new_sidebar_cross {
  position: absolute;
  color: white;
  top: 18px;
  right: 15px;
  font-size: 25px;
  display: none;
}

/*  */

.lead_table_searchbar {
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: -webkit-fill-available;
  background-color: white;
  box-shadow: 0px 0px 4px 0px #d9d9d9;
}

.lead_table_searchbar .form_table_nav_input {
  border-radius: 8px;
  border: 1px solid var(--primary-violet);
  padding: 4px;
  display: flex;
  align-items: center;
  width: 30%;
}

.form_table_nav_input input {
  width: 100%;
  padding: 8px;
  border: none;
  background-color: transparent;
  outline: none;
}

.form_table_nav_input button {
  border-radius: 9px;
  background: var(--primary-violet);
  padding: 4px 8px;
  color: var(--main-white);
  text-align: center;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  border: 1px solid var(--primary-violet);
}

/*  */
.company_hierarchy_header {
  display: flex;
  align-items: center;
  padding: 12px 36px;
  justify-content: space-between;
  background-color: white;
}

.company_hierarchy_header h2 {
  font-size: 22px;
  font-weight: 500;
  gap: 8px;
  display: flex;
  align-items: center;
  color: #3b3b3b;
}

.company_hierarchy_header button {
  padding: 12px 20px;
  border: 1px solid #024c87;
  background-color: transparent;
  border-radius: 6px;
  color: #024c87;
  font-weight: 400;
  font-size: 16px;
}

.company_header_container .company_hierarchy_container {
  padding-top: 60px;
}

.company_hierarchy_container .company_hierarchy_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.company_hierarchy_container
  .company_hierarchy_div
  .company_hierarchy_box_container {
  display: flex;
  gap: 30px;
}

.company_hierarchy_box_container .company_hierarchy_box h3 {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}

.company_hierarchy_div .company_hierarchy_main_box {
  width: 251px;
  height: 213px;
  border: 1px solid #bed8ed;
  background-color: #e5f2fc;
  border-radius: 8px;
  padding: 16px 7px;
}

.company_hierarchy_div .company_hierarchy_main_box_header {
  text-align: center;
}

.company_hierarchy_main_box_header h2 {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 10px;
  /* align-items: center; */
  color: #333333;
  border-bottom: 1px solid #bed8ed;
}

.company_hierarchy_box_container .company_hierarchy_box {
  width: 251px;
  height: 213px;
  border: 1px solid #ffb4a8;
  background-color: #faedec;
  border-radius: 8px;
  padding: 16px 7px;
}

.company_hierarchy_box .company_hierarchy_box_header {
  text-align: center;
}

.company_hierarchy_box_header h3 {
  border-bottom: 1px solid #ffb4a8;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.company_hierarchy_box_header h3 span {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 18px;
}

.hierarchy_modal_container h2 {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 20px;
}

.hierarchy_modal_container .hierarchy_modal_input {
  margin-bottom: 20px;
  position: relative;
}

.hierarchy_modal_input .dropdown_icon {
  position: absolute;
  right: 10px;
  top: 41px;
  font-size: 22px;
  color: #5c5c5c;
}

.hierarchy_modal_container .hierarchy_modal_input h3 {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}

.hierarchy_modal_input input {
  border: 1px solid #b8b8b8;
  border-radius: 8px;
  width: 100%;
  height: 47px;
  padding: 5px 18px;
  color: #dddddd;
  font-size: 16px;
  outline: none;
  font-weight: 400;
}

.hierarchy_modal_input input::placeholder {
  color: #dddddd;
}

.hierarchy_modal_input select {
  border: 1px solid #b8b8b8;
  border-radius: 8px;
  width: 100%;
  color: #dddddd;
  font-size: 16px;
  height: 47px;
  padding: 5px 18px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.hierarchy_modal_container .hierarchy_submit_btn {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 14px;
}

.hierarchy_submit_btn .save_btn {
  background-color: #024c87;
  color: white;
  border-radius: 6px;
  border: none;
  outline: 0;
  width: 75px;
  height: 35px;
  font-size: 16px;
  font-weight: 400;
}
.hierarchy_submit_btn .cancel_btn {
  background: transparent;
  outline: 0;
  border: 1px solid #b50000;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 400;
  width: 92px;
  height: 35px;
  color: #b50000;
}

.company_hierarchy_main_box .company_hierarchy_main_box_user {
  display: flex;
  flex-direction: column;
  height: 150px;
  overflow-y: auto;
}

.company_hierarchy_main_box_user .company_hierarchy_main_box_user_div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  padding-right: 10px;
  justify-content: space-between;
}

.company_hierarchy_main_box_user_div img {
  width: 30px;
  object-fit: cover;
  height: 30px;
}

.company_hierarchy_main_box_user_div p {
  margin: 0;
}

/* operation offer header start  */
.operation_offer_header {
  padding-block: 30px;
  display: flex;
  align-items: center;
}

.operation_offer_header .operation_offer_input {
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  width: 300px;
  height: 40px;
  position: relative;
}

.operation_offer_input input {
  border: 0;
  outline: none;
  width: 100%;
  height: 100%;
  background: transparent;
  color: #bbbbbb;
  font-weight: 400;
  font-size: 16px;
  padding-left: 40px;
}

.operation_offer_input input::placeholder {
  color: #d9d9d9;
}

.operation_offer_input .search_icon {
  position: absolute;
  left: 13px;
  top: 9px;
  font-size: 20px;
  color: #d9d9d9;
}

.operation_offer_header span {
  margin-left: 20px;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 8px;
}

.operation_offer_header span .filter_icon {
  font-size: 24px;
  color: #1d2939;
}

.operation_offer_header .operation_offer_buttons {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}

.operation_offer_header .operation_offer_buttons .add_new {
  border: 1px solid #024c87;
  background: transparent;
  color: #024c87;
  font-size: 16px;
  border-radius: 6px;
  width: 107px;
  height: 37px;
  /* margin-left: auto; */
  font-weight: 400;
}

.operation_offer_header .operation_offer_buttons .upload {
  background: #52bb38;
  width: 90px;
  color: white;
  height: 35px;
  font-size: 16px;
  font-weight: 500;
  border: 0;
  outline: 0;
  border-radius: 8px;
  text-transform: capitalize;
}

.operation_offer_letter_table {
  border: 1px solid #e4e7ec;
  border-radius: 12px;
}

.operation_offer_letter_table .operation_offer_letter_header {
  padding: 20px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.operation_offer_letter_header h2 {
  color: #101828;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.operation_offer_letter_header .three-dots {
  color: #98a2b3;
}

.operation_offer_letter_table table {
  width: 100%;
}

.operation_offer_letter_table table thead {
  background-color: #f9fafb;
  border-block: 1px solid #e4e7ec;
}

.operation_offer_letter_table table thead th {
  padding: 12px 24px;
  color: #475467;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}

.operation_offer_letter_table table tbody tr {
  border-bottom: 1px solid #e4e7ec;
}

/* .operation_offer_letter_table table tbody tr:last-child {
  border: none;
} */

.operation_offer_letter_table table tbody td {
  padding: 16px 24px;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  color: #475467;
}

.operation_offer_letter_table table tbody .table_name {
  font-weight: bold;
  color: #101828;
}

.operation_offer_letter_table .table_icon {
  display: flex;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  justify-content: space-between;
}

.operation_offer_letter_table .operation_offer_paginate ul {
  margin: 0;
}

.offer_letter_table_div .offer_letter_table_paginate ul .next {
  margin-left: auto;
}
.offer_letter_table_div .offer_letter_table_paginate ul .previous {
  margin-right: auto;
}

.operation_upload_modal h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
}

.operation_upload_modal .operation_upload_data_input {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.operation_upload_data_input .operation_upload_data_inputdiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.operation_upload_data_inputdiv label {
  color: #4e4e4e;
  font-size: 20px;
  font-weight: 500;
}

.operation_upload_data_inputdiv input,
select {
  box-shadow: 0px 0px 4.1px 0px #ededed;
  border: 0;
  outline: 0;
  height: 49px;
  width: 250px;
  border-radius: 8px;
  padding: 16px;
}

.operation_upload_data_inputdiv select {
  padding-block: 0;
}

.operation_upload_data_inputdiv input::placeholder {
  color: #c1c1c1;
  font-size: 16px;
  font-weight: 400;
}

.operation_upload_data_input button {
  background-color: #52bb38;
  border-radius: 8px;
  color: white;
  width: 160px;
  height: 43px;
  border: 0;
  margin-left: auto;
  outline: 0;
}

.operation_offer_paginate {
  padding: 12px 24px;
}

.operation_offer_paginate ul {
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.operation_offer_paginate .previous {
  margin-right: auto;
}
.operation_offer_paginate .next {
  margin-left: auto;
}

.operation_offer_paginate .prev-button,
.next-button {
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  color: black;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  outline: none;
  width: fit-content;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  /* border: 1px solid #d0d5dd; */
}
/* .operation_offer_paginate .next-button {
} */
.import_leads_header {
  padding: 16px 36px;
  display: flex;
  background: white;
  align-items: center;
  justify-content: space-between;
}

.import_leads_header span {
  font-weight: 500;
  font-size: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(59, 59, 59, 1);
}

.import_leads_header button {
  background: transparent;
  border: 0;
  outline: 0;
  width: 90px;
  height: 35px;
  font-weight: 400;
  font-size: 16px;
  color: rgba(2, 76, 135, 1);
  border-radius: 6px;
  border: 1px solid rgba(2, 76, 135, 1);
}

.import_leads_container {
  background-color: white;
  margin-top: 6px;
  padding: 32px 44px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.import_leads_container p {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  color: rgba(92, 92, 92, 1);
}

.import_leads_container label {
  background: rgba(2, 76, 135, 1);
  width: 110px;
  height: 38px;
  color: white;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.import_leads_container .import_leads_file_select {
  display: flex;
  align-items: flex-start;
  width: 420px;
  justify-content: space-between;
}

.import_leads_container .import_leads_file_select h2 {
  color: rgba(92, 92, 92, 1);
  font-size: 24px;
  font-weight: 500;
}

.import_leads_container .import_leads_field_mapping label {
  background: transparent;
  border: 1px solid rgba(2, 76, 135, 1);
  color: rgba(2, 76, 135, 1);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.import_leads_result_header {
  display: flex;
  padding: 16px 36px;
  align-items: center;
  justify-content: space-between;
}

.import_leads_result_header h2 {
  font-size: 24px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
}

.import_leads_result_header span {
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  font-weight: 400;
}

.import_leads_result_container {
  padding: 0 36px;
}

.import_leads_result_container .import_leads_result_file_div {
  display: flex;
  gap: 150px;
  padding-block: 24px;
  border-bottom: 2px solid rgba(217, 217, 217, 1);
}

.import_leads_result_file_div h3 {
  color: rgba(92, 92, 92, 1);
  font-size: 24px;
  font-weight: 500;
}

.import_leads_result_file_div .import_leads_result_file_div_img {
  width: 250px;
  height: 90px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 700;
  padding: 16px;
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  display: flex;
  align-items: center;
  gap: 12px;
}

.import_leads_result_file_div_img img {
  width: 47px;
  height: 57px;
}

.import_leads_result_field_div {
  padding-block: 25px;
  display: flex;
  align-items: baseline;
  gap: 100px;
}

.import_leads_result_field_div h3 {
  color: rgba(92, 92, 92, 1);
  font-size: 24px;
  font-weight: 500;
}

.import_leads_result_field_div h4 {
  color: rgba(92, 92, 92, 1);
  font-size: 20px;
  margin: 0;
  font-weight: 500;
}

.import_leads_result_field_div p {
  color: rgba(92, 92, 92, 1);
  font-size: 18px;
  margin: 0;
  font-weight: 400;
}

.import_leads_result_field_div .import_leads_result_field_content_header {
  display: flex;
  gap: 100px;
}

.import_leads_result_field_div .import_leads_result_field_data {
  display: flex;
  gap: 130px;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
}

.import_leads_result_field_div .import_leads_result_field_data select {
  border: 1px solid rgba(217, 217, 217, 1);
  background: transparent;
  border-radius: 8px;
  height: auto;
  width: 370px;
  color: rgba(92, 92, 92, 1);
  font-weight: 400;
  font-size: 18px;
  appearance: none;
  /*  */
}

.import_leads_result_field_div .import_leads_result_field_data_select {
  position: relative;
}

.import_leads_result_field_data_select .down_icon {
  position: absolute;
  font-size: 22px;
  right: 10px;
  color: rgba(92, 92, 92, 1);
  top: 19px;
}

.import_leads_result_field_content .import_leads_result_buttons {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  margin-top: 40px;
  /*  */
}

.import_leads_result_buttons button {
  padding: 14px 20px;
  height: 42px;
  display: flex;
  font-size: 20px;
  color: white;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: none;
  border-radius: 5px;
}

.import_leads_result_buttons .save_btn {
  background-color: rgba(82, 187, 56, 1);
  /*  */
}

.import_leads_result_buttons .cancel_btn {
  background-color: rgba(244, 244, 244, 1);
  color: rgba(140, 140, 140, 1);
  /*  */
}

.ticket_queries_outer_container {
  /* box-shadow: 0px 0px 7px 0px rgba(227, 227, 227, 1); */
  background: white;
  /* border-radius: 5px; */
  /* padding: 14px; */
  height: 100vh;
  /* margin: 10px; */
}

.ticket_queries_outer_container .ticket_queries_inner_container {
  border: 1px solid rgba(234, 236, 240, 1);
  border-radius: 8px;
  height: 90vh;
  overflow-y: auto;
  margin: 8px;
}

.ticket_queries_outer_container .ticket_table_query {
  width: 220px;
}

.row {
  --bs-gutter-x: 0;
}

.ticket_queries_header {
  padding: 16px 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ticket_queries_header h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  color: rgba(59, 59, 59, 1);
  display: flex;
  align-items: center;
  gap: 9px;

  /*  */
}

.ticket_queries_header .ticket_queries_header_right {
  display: flex;
  align-items: center;
  gap: 25px;
  position: relative;
}

.ticket_queries_header .ticket_queries_header_right span {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 7px;
  font-weight: 500;
  color: rgba(52, 64, 84, 1);
}

.ticket_queries_inner_container table {
  width: 100%;
}

.ticket_queries_inner_container table input {
  width: 100%;
  height: 100%;
}

.ticket_queries_inner_container table thead th {
  padding: 12px;
  background: rgba(252, 252, 253, 1);
  border-block: 1px solid rgba(234, 236, 240, 1);
  color: rgba(102, 112, 133, 1);
  font-size: 14px;
  font-weight: 500;
}

.ticket_queries_inner_container table tbody td {
  padding: 12px;
  color: rgba(102, 112, 133, 1);
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid rgba(234, 236, 240, 1);
}

.ticket_queries_inner_container table tbody td select {
  width: fit-content;
}

.ticket_queries_inner_container .ticket_queries_paginate {
  padding-bottom: 0;
}

/* .ticket_queries_inner_container table tr {
  padding: 12px 24px;
} */

.ticket_queries_inner_container table tbody td .dropdown {
  position: relative;
  border-radius: 16px;
  padding: 2px 8px;
  background-color: rgba(236, 253, 243, 1);
  width: fit-content;
}

.ticket_queries_inner_container table tbody td .dropdown .dropdown-button {
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
}

.ticket_queries_inner_container table tbody td .dropdown .dropdown-button .dot {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 10px;
}

.ticket_queries_inner_container table tbody td .dropdown .dropdown-content {
  position: absolute;
  list-style: none;
  left: 0;
  top: 0;
  border-radius: 4px;
  border: 1px solid rgba(217, 217, 217, 1);
  width: 100%;
  background: white;
  padding: 6px;
  z-index: 99;
}

.ticket_queries_inner_container table tbody td .dropdown .dropdown-content li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ticket_queries_inner_container
  table
  tbody
  td
  .dropdown
  .dropdown-content
  li
  input {
  width: 8px;
  height: 8px;
}

.ticket_chats_container {
  /* margin: 10px; */
  box-shadow: 0px 0px 7px 0px rgba(227, 227, 227, 1);
  border-radius: 5px;
  height: 100vh;
}

.ticket_chats_container .ticket_chats_header {
  padding: 15px 40px;
}

.ticket_chats_container .ticket_chats_header h2 {
  font-size: 24px;
  font-weight: 500;
  color: rgba(59, 59, 59, 1);
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

/*  */
.ticket_chats_div {
  display: flex;
}

.ticket_chats_div .ticket_chats_list {
  width: 410px;
  height: 90vh;
  border: 1px solid rgba(213, 217, 221, 1);
}

.ticket_chats_div .ticket_chats_list .ticket_chats_list_header {
  border-bottom: 1px solid rgba(213, 217, 221, 1);
  /* height: 50px; */
  padding: 10px 40px;
  background-color: white;
  height: 62px;
}

.ticket_chats_list_header .ticket_chats_search_input {
  position: relative;
}

.ticket_chats_search_input input {
  width: 100%;
  outline: none;
  padding: 8px 32px;
  border-radius: 6px;
  border: 1px solid rgba(217, 217, 217, 1);
  font-size: 14px;
  font-weight: 400;
  color: rgba(154, 163, 171, 1);
}

.ticket_chats_search_input .search_icon {
  position: absolute;
  color: rgba(154, 163, 171, 1);
  top: 9px;
  left: 9px;
  font-size: 20px;
}

.ticket_chats_div .ticket_chats_box {
  width: 50%;
  /* display: flex; */
}

.ticket_chats_box .ticket_chats_box_header {
  display: flex;
  border-block: 1px solid rgba(213, 217, 221, 1);
  align-items: center;
  padding: 10px;
  height: 62px;
}

.ticket_chats_box_header .ticket_chats_box_user_div {
  width: 33px;
  height: 33px;
  background-color: rgba(79, 175, 203, 1);
  border-radius: 2px;
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  margin-right: 20px;
}

.ticket_chats_div .ticket_chats_user_details {
  width: 20%;
  border: 1px solid rgba(213, 217, 221, 1);
}

.ticket_chats_user_details .ticket_chats_user_details_header {
  border-bottom: 1px solid rgba(213, 217, 221, 1);
  height: 62px;
  display: flex;
  align-items: center;
  padding: 10px;
  color: rgba(3, 24, 73, 1);
  font-size: 14px;
  font-weight: 400;
}

.ticket_chats_list .ticket_chats_listitem {
  border-block: 1px solid rgba(211, 216, 221, 1);
  border-top: 0;
  padding: 15px 40px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.ticket_chats_listitem.active {
  background-color: rgba(243, 245, 250, 1);
}

.ticket_chats_listitem .ticket_chats_listitem_user {
  background-color: rgba(79, 175, 203, 1);
  width: 38px;
  height: 38px;
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.ticket_chats_listitem .ticket_chats_listitem_badge_div {
  display: flex;
  gap: 10px;
}

.ticket_chats_listitem_badge_div span {
  font-size: 11px;
  font-weight: 400;
  border-radius: 2px;
}

.ticket_chats_listitem_badge_div span:first-child {
  background: rgba(255, 236, 240, 1);
  border: 1px solid rgba(254, 213, 219, 1);
  color: rgba(200, 32, 36, 1);
  padding-inline: 3px;
}

.ticket_chats_listitem_badge_div span:last-child {
  background: rgba(204, 218, 249, 1);
  border: 1px solid rgba(179, 192, 221, 1);
  color: rgba(44, 94, 198, 1);
  padding-inline: 3px;
}

.ticket_chats_listitem p {
  font-size: 13px;
  color: rgba(3, 24, 73, 1);
  font-weight: 400;
  margin-block: 4px;
}

.ticket_chats_listitem p span {
  font-size: 12px;
  color: rgba(79, 175, 203, 1);
  margin-left: 4px;
}

.ticket_chats_listitem .ticket_chats_listitem_author_name {
  display: flex;
  align-items: center;
  color: rgba(154, 163, 171, 1);
  font-size: 11px;
  font-weight: 400;
}

.ticket_chats_listitem_author_name .dot {
  width: 4px;
  height: 4px;
  background-color: rgba(154, 163, 171, 1);
  border-radius: 10px;
  margin-inline: 6px;
}

.ticket_chats_user_details .ticket_chats_user_details_div {
  /* border-inline: 1px solid rgba(213, 217, 221, 1); */
  padding: 10px;
}

.ticket_chats_user_details_div .ticket_chats_user_contact_box {
  border: 1px solid rgba(213, 217, 221, 1);
  border-radius: 4px;
  overflow: hidden;
}

.ticket_chats_user_contact_box .contact_box_header {
  background-color: rgba(243, 245, 250, 1);
  border-bottom: 1px solid rgba(213, 217, 221, 1);
  font-size: 13px;
  font-weight: 400;
  color: rgba(3, 24, 73, 1);
  padding: 8px;
}

.ticket_chats_user_contact_box .ticket_chats_user_contact_box_content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.compose_email_edit_text p {
  margin-bottom: 6px;
}

/* .ticket_chats_user_contact_box_content span h5,
h6,
p {
  font-size: 11px;
  font-weight: 400;
  text-transform: capitalize;
} */

/* .ticket_chats_user_contact_box_content span h5 {
  color: rgba(154, 163, 171, 1);
  margin: 0;
}

.ticket_chats_user_contact_box_content span p {
  color: rgba(3, 24, 73, 1);
  font-size: 11px; 
  font-weight: 400; 
  margin: 0;
} */

/* .ticket_chats_user_contact_box_content span h6 {
  color: rgba(79, 175, 203, 1);
} */

.ticket_chats_box .ticket_chats_box_container {
  padding: 20px 10px;
  padding-bottom: 0px;
}

.ticket_chats_box_container .ticket_chats_box_receiver {
  display: flex;
  gap: 20px;
}

.ticket_chats_box_receiver .ticket_chats_box_receiver_profile {
  width: 33px;
  height: 33px;
  background-color: rgba(79, 175, 203, 1);
  color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
}

.ticket_chats_box_receiver .ticket_chats_box_receiver_message {
  background-color: rgba(79, 175, 203, 1);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 5px;
  border-radius: 3px;
}

.ticket_chats_box_container p {
  background-color: rgba(79, 175, 203, 1);
  color: white;
  display: flex;
  /* align-items: center; */
  border-radius: 3px;
  font-size: 13px;
  font-weight: 400;
  padding: 10px 20px;
  margin-top: 10px;
  justify-content: center;
  /* width: 360px; */
  margin-left: auto;
  margin-bottom: 14px;
  width: fit-content;
}

.ticket_chats_box_container span {
  margin-left: auto;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  justify-content: end;
  color: rgba(3, 24, 73, 1);
}

.ticket_chats_box_container .ticket_chats_box_giver {
  justify-content: end;
  margin-top: 50px;
  flex-direction: row-reverse;
}

.ticket_chats_container .ticket_chats_box_giver_reply {
  margin-left: 0;
  margin-right: 52px;
  background-color: rgba(243, 245, 250, 1);
  color: rgba(3, 24, 73, 1);
}

.ticket_chats_box_giver .ticket_chats_box_receiver_profile {
  background-color: rgba(243, 245, 250, 1);
  color: rgba(3, 24, 73, 1);
}

.ticket_chats_box_giver .ticket_chats_box_receiver_message {
  color: rgba(3, 24, 73, 1);
  background-color: rgba(243, 245, 250, 1);
}

.ticket_chats_box_container .ticket_chats_box_giver_last_seen {
  margin-right: 50px;
}

.ticket_chats_box_container .ticket_chat_box_textarea {
  width: 100%;
  margin-top: 10px;
  position: relative;
}

.ticket_chat_box_textarea textarea {
  width: 100%;
  border: 1px solid rgba(211, 216, 221, 1);
  border-radius: 5px;
  outline: none;
  padding: 5px;
  height: 120px;
  text-align: left;
  resize: none;
}

.ticket_chat_box_textarea button {
  position: absolute;
  right: 10px;
  bottom: 15px;
  background-color: rgba(79, 175, 203, 1);
  color: white;
  border-radius: 4px;
  border: none;
  outline: none;
  width: 70px;
  height: 30px;
  cursor: pointer;
}

.ticket_chats_user_details .ticket_chats_user_ticket_dropdown {
  border: 1px solid rgba(213, 217, 221, 1);
  border-radius: 4px;
  margin-top: 40px;
  overflow: hidden;
}

.ticket_chats_user_ticket_dropdown .ticket_chats_user_ticket_dropdown_header {
  padding: 8px;
  background-color: rgba(243, 245, 250, 1);
  font-size: 13px;
  font-weight: 400;
  border-bottom: 1px solid rgba(213, 217, 221, 1);
}

.ticket_chats_user_ticket_dropdown .ticket_chats_user_ticket_dropdown_content {
  padding: 10px;
  position: relative;
}

.ticket_chats_user_ticket_dropdown_content
  .ticket_chats_user_ticket_dropdown_item {
  border-bottom: 1px solid rgba(213, 217, 221, 1);
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.ticket_chats_user_ticket_dropdown_content
  .ticket_chats_user_ticket_dropdown_item:last-child {
  margin-bottom: 10px;
  border-bottom: 0;
}

.ticket_chats_user_ticket_dropdown_content
  .ticket_chats_user_ticket_dropdown_item
  h4 {
  color: rgba(154, 163, 171, 1);
  font-size: 15px;
  font-weight: 400;
}

.ticket_chats_user_ticket_dropdown_item li {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(80, 80, 80, 1);
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 400;
}

.ticket_chats_user_ticket_dropdown_content
  .ticket_chats_user_ticket_dropdown_item_list {
  border: 1px solid rgba(213, 217, 221, 1);
  border-radius: 6px;
  padding: 10px;
  /* position: absolute; */
  top: 10px;
  background-color: white;
  width: 92%;
  /* border-bottom: 1px solid rgba(213, 217, 221, 1); */
}

.ticket_chats_user_ticket_dropdown_item_list h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(154, 163, 171, 1);
  font-weight: 400;
  font-size: 16px;
}

.ticket_chats_user_ticket_dropdown_item_list button {
  background-color: rgba(79, 175, 203, 1);
  color: white;
  border-radius: 4px;
  border: none;
  outline: none;
  width: 70px;
  height: 26px;
  cursor: pointer;
  margin-top: 10px;
}

.ticket_chats_user_ticket_dropdown_item_list li {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: rgba(82, 81, 81, 1);
}

.evidence_modal_container .upload_multiple {
  background-color: rgba(82, 187, 56, 1);
  border-radius: 8px;
  padding: 9px 16px;
  width: fit-content;
  margin: auto;
  margin-top: 20px;
  color: white;
}

.evidence_modal_container .upload_multiple label p {
  font-size: 18px;
  cursor: pointer;
  font-weight: 500;
  margin: 0;
}

.evidence_modal_container .evidence_modal_preview_image {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
}

.evidence_modal_container .evidence_modal_preview_image_div {
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  border-radius: 8px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.evidence_modal_container .evidence_modal_preview_image_div p {
  margin: 0;
  font-size: 14px;
}

.evidence_modal_container .evidence_modal_preview_image img {
  width: 100px;
}

.evidence_modal_container .evidence_modal_file {
  margin-block: 10px;
}

.evidence_modal_container .upload_multiple {
  background-color: rgba(82, 187, 56, 1);
  border-radius: 8px;
  padding: 9px 16px;
  width: fit-content;
  margin: auto;
  margin-top: 20px;
  color: white;
}

.evidence_modal_container .evidence_modal_preview_image {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
}

.evidence_modal_container .evidence_modal_preview_image_div {
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  border-radius: 8px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.evidence_modal_container .evidence_modal_preview_image_div p {
  margin: 0;
  font-size: 14px;
}

.evidence_modal_container .evidence_modal_preview_image img {
  width: 100px;
}

.evidence_modal_container .evidence_modal_file {
  margin-block: 10px;
}

.ticket_chats_list .dashboard_ticket_chats_no_found {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
}

.ticket_chats_listitem .ticket_chats_listitem_notification {
  background: rgba(79, 175, 203, 1);
  height: 20px;
  color: #fff;
  width: 20px;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  position: absolute;
  top: 25px;
  right: 10px;
}

.ticket_chats_container .ticket_chat_notallowed {
  font-size: 16px;
  color: #c0c0c0;
  text-align: center;
  margin-top: 20px;
  font-weight: 400;
  margin-top: 14px;
}

.ticket_chat_notallowed_divider {
  height: 2px;
  width: 100%;
  background-color: #c0c0c0;
  margin-top: 14px;
}

.email_container {
  position: relative;
}

.email_header {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 16px 20px;
  justify-content: space-between;
  box-shadow: 0px 0px 4px 0px #d9d9d9;
  position: fixed;
  width: -webkit-fill-available;
}

.email_header h2 {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}

.email_header .email_header_right_side {
  display: flex;
  align-items: center;
  gap: 20px;
}

.email_header_right_side .email_header_search_input {
  /* width: 300px;
  height: 40px;
  position: relative;
  box-shadow: 0px 0px 4px 0px #d9d9d9;
  border-radius: 8px;
  overflow: hidden; */
  /*  */
  font-size: 14px;
  outline: none;
  width: 300px;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid rgba(217, 217, 217, 1);
  font-weight: 400;
  position: relative;
  color: rgba(154, 163, 171, 1);
}

.email_header_search_input .search_icon {
  position: absolute;
  color: #717171;
  top: 9px;
  left: 13px;
}

.email_header_search_input input {
  width: 100%;
  border: 0;
  height: 100%;
  padding-left: 30px;
  outline: 0;
  color: #bbbbbb;
  font-size: 16px;
  font-weight: 400;
}

.email_header_right_side .email_header_btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #717171;
  border-radius: 4px;
  box-shadow: 0px 0px 4.4px 0px #d9d9d9;
}

.email_chat_list_container {
  padding: 10px;
  background-color: white;
  height: 100vh;
  padding-top: 80px;
}

.email_chat_list_container .email_chat_list {
  display: flex;
  gap: 15px;
  cursor: pointer;
  padding: 20px 16px;
}

.email_chat_list img {
  width: 52px;
  height: 51px;
  border-radius: 16px;
  object-fit: cover;
}

.email_chat_list_content p {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  color: #262626;
  text-transform: none;
}

.email_chat_list_content span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.email_chat_list_content span .active {
  background-color: #024c87;
  padding: 2px 12px;
  border-radius: 4px;
  color: #fff;
}

.email_chat_list_content span h3 {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  color: #262626;
}

.email_chat_list_content span h6 {
  color: #262626;
  font-size: 12px;
  margin: 0;
  font-weight: 300;
}

.email_chat_cta {
  position: fixed;
  background-color: #024c87;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  right: 5%;
  cursor: pointer;
  bottom: 10%;
  color: white;
  display: flex;
  font-size: 28px;
  align-items: center;
  justify-content: center;
}

.compose_email_box {
  box-shadow: 0px 0px 4px 0px #d9d9d9;
  position: fixed;
  top: 0;
  right: 0;
  background-color: white;
  padding: 30px 35px;
  width: 600px;
  height: 100vh;
  overflow-y: auto;
  /* transition: all 0.5s ease; */
}

.compose_email_box.active {
  /* position: absolute; */
  width: 83%;
  transition: all 0.5s ease;
  position: fixed;
  top: 0;
  height: 100vh;
}

.compose_email_box .compose_email_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.compose_email_top h2 {
  font-size: 24px;
  font-weight: 700;
  color: #2d2c2c;
}

.compose_email_top span {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 20px;
}

.compose_email_input .email_to {
  border: 0;
  border-bottom: 1px solid #d9d9d9;
  outline: 0;
  padding: 5px 0 18px 10px;
  color: #a6a7a7;
  font-size: 20px;
  width: 100%;
  font-weight: 400;
}

.compose_email_input h2 {
  font-size: 20px;
  font-weight: 500;
  color: #2d2c2c;
  margin-top: 20px;
}

.compose_email_input .compose_email_search_input {
  position: relative;
  box-shadow: 0px 0px 4px 0px #d9d9d9;
  border-radius: 8px;
  margin-bottom: 20px;
}

.compose_email_search_input input {
  border: 0;
  outline: 0;
  color: #bbbbbb;
  font-size: 16px;
  padding: 10px 0 10px 8px;
}

.compose_email_search_input .search_icon {
  margin-left: 20px;
  color: #717171;
}

.compose_email_input .email_sub {
  border: 0;
  border-bottom: 1px solid #d9d9d9;
  outline: 0;
  padding: 13px 0 9px 10px;
  color: #a6a7a7;
  font-size: 20px;
  width: 100%;
  font-weight: 400;
}

.compose_email_box .compose_email_bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.compose_email_bottom button:first-child {
  border: 1px solid #024c87;
  outline: 0;
  border-radius: 8px;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 600;
  background: transparent;
  color: #024c87;
}

.compose_email_bottom button:last-child {
  border: 1px solid #2da562;
  outline: 0;
  padding: 12px 20px;
  border-radius: 50px;
  background-color: #2da562;
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.compose_email_template {
  background-color: white;
  position: absolute;
  padding: 20px 37px;
  width: 100%;
  bottom: 0;
  left: 0;
  box-shadow: 0px -1px 4px 0px #9a9a9a40;
}

.compose_email_template .compose_email_template_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  cursor: pointer;
}

.compose_email_template_top h2 {
  color: #0f0f0f;
  font-size: 20px;
  font-weight: 500;
}

.compose_email_template .compose_email_search_input {
  box-shadow: 0px 0px 4px 0px #d9d9d9;
  border-radius: 8px;
}

.compose_email_search_input .search {
  margin-left: 20px;
  color: #717171;
}

.compose_email_search_input input {
  padding: 10px 0 10px 10px;
  color: #bbbbbb;
  width: 95%;
}

.compose_email_template .compose_email_save {
  margin-top: 20px;
}

.compose_email_save h2 {
  font-size: 20px;
  font-weight: 500;
  color: #0f0f0f;
  margin-bottom: 20px;
}

.compose_email_save .compose_email_save_option {
  display: flex;
  flex-direction: column;
}

.compose_email_save_option span {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #0f0f0fbf;
}

.compose_email_save button {
  background-color: #024c87;
  color: white;
  padding: 12px 24px;
  width: 100%;
  border: 0;
  outline: 0;
  font-size: 15px;
  border-radius: 8px;
  margin-top: 20px;
  font-weight: 600;
}

.email_chats_content .email_chats_content_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.email_chats_content_top .email_chats_content_head {
  display: flex;
  align-items: center;
  gap: 20px;
}

.email_chats_content_head .arrow_left {
  color: #0000008a;
}

.email_chats_content_head h2 {
  color: #202124;
  font-size: 28px;
  font-weight: 400;
  margin: 0;
}

.email_chats_content_top .trash_icon {
  color: #0000008a;
  cursor: pointer;
}

.email_chats_content_profile_container {
  padding: 0 20px;
  background-color: white;
  /* height: 400px;
  overflow-y: auto; */
}

.email_chats_content_profile_container .email_chats_content_profile {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.email_chats_content_profile .email_chats_content_profile_left {
  display: flex;
  gap: 10px;
}
.email_chats_content_profile_left img {
  width: 40px;
  height: 40px;
}

.email_chats_content_profile_left .email_chats_content_mail h4 {
  font-size: 14px;
  color: #202124;
  font-weight: 400;
  margin-bottom: 4px;
}

.email_chats_content_mail .active {
  background-color: #024c87;
  padding: 2px 12px;
  border-radius: 4px;
  color: #fff;
  font-size: 18px;
}

.email_chats_content_mail h4 span {
  color: #0000008a;
  font-size: 14px;
  font-weight: 400;
  margin-left: 4px;
}

.email_chats_content_mail h5 {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #0000008a;
  font-weight: 400;
  cursor: pointer;
  position: relative;
}

.email_chats_content_mail h5 .show_batch_modal_div {
  position: absolute;
  top: 23px;
  right: -250px;
  width: 380px;
  box-shadow: 0px 0px 4px 0px #d9d9d9d4;
  padding: 24px;
  background-color: white;
}

.show_batch_modal_div .show_batch_modal_content {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.show_batch_modal_div .show_batch_modal_content h5 {
  display: flex;
  align-items: center;
  color: #202124;
  font-size: 16px;
  font-weight: 500;
  width: 85px;
  justify-content: space-between;
  margin-bottom: 0;
}

.show_batch_modal_div .show_batch_modal_content p {
  color: #0000008a;
  font-size: 15px;
  font-weight: 500;
  margin-left: 20px;
  margin-bottom: 0;
}

.email_chats_content_profile .email_chats_content_forward {
  display: flex;
  align-items: center;
  gap: 12px;
}

.email_chats_content_forward p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #00000099;
}

.email_chats_content_forward .forward {
  color: #0000005e;
}

.email_chats_content_profile_container p {
  color: #000000;
  /* margin-left: 50px; */
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 6px;
}

.email_chats_content_profile_container .email_chats_content_underline {
  width: 80%;
  height: 1px;
  background-color: #edeff1;
  margin: auto;
  margin-top: 100px;
}

.enroll_student_details_container {
  background-color: white;
  /* margin: 5px; */
  box-shadow: 0px 0px 7px 0px rgba(227, 227, 227, 1);
  padding-bottom: 20px;
  /* height: inherit; */
  /* height: 89vh; */
}

.enroll_student_details_container .enroll_student_details_header {
  display: flex;
  align-items: center;
  padding: 20px 20px;
}

.enroll_student_details_header h2 {
  color: rgba(16, 24, 40, 1);
  font-size: 24px;
  margin: 0;
  font-weight: 600;
}

.enroll_student_details_header .enroll_student_details_search_input {
  margin-left: auto;
  width: 315px;
  height: 44px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  position: relative;
}

.enroll_student_details_search_input input {
  width: 100%;
  height: 100%;
  border: 0;
  padding-left: 40px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(187, 187, 187, 1);
  outline: 0;
}

.enroll_student_details_search_input .search_icon {
  position: absolute;
  left: 11px;
  top: 12px;
  font-size: 20px;
  color: gray;
}
/* .offer_letter_table_head button */
.enroll_student_switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 27px;
  margin-left: 19px;
}

/* Hide default HTML checkbox */
.enroll_student_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.enroll_student_switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.enroll_student_switch .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.enroll_student_switch input:checked + .slider {
  background-color: rgba(83, 182, 53, 1);
}

.enroll_student_switch input:focus + .slider {
  box-shadow: 0 0 1px rgba(83, 182, 53, 1);
}

.enroll_student_switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(23px);
}

/* Rounded sliders */
.enroll_student_switch .slider.round {
  border-radius: 34px;
}

.enroll_student_switch .slider.round:before {
  border-radius: 50%;
}
/*  */
.enroll_student_payment_table {
  padding-inline: 20px;
  height: 65vh;
  overflow-y: auto;
}

.enroll_student_payment_table h2 {
  color: rgba(16, 24, 40, 1);
  font-size: 24px;
  font-weight: 600;
}

.enroll_student_payment_table table {
  width: 100%;
}

/*  */
.enroll_student_payment_table table thead {
  background-color: rgba(249, 250, 251, 1);
}

.enroll_student_payment_table table thead th {
  padding: 12px;
  border-bottom: 1px solid rgba(234, 236, 240, 1);
  color: rgba(71, 84, 103, 1);
  font-size: 16px;
  font-weight: 500;
}

.enroll_student_payment_table table tbody td {
  padding: 12px;
  color: rgba(102, 112, 133, 1);
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  border-bottom: 1px solid rgba(234, 236, 240, 1);
  cursor: pointer;
}

.enroll_student_payment_table table tbody {
  position: relative;
}

/*  */
.enroll_student_payment_table table tbody td .enroll_student_table_name {
  display: flex;
  align-items: center;
  gap: 10px;
}

.enroll_student_table_name img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.enroll_student_table_name h3 {
  margin: 0;
  color: rgba(16, 24, 40, 1);
  font-size: 14px;
  font-weight: 500;
}

.enroll_student_payment_table table tbody td button {
  background-color: rgba(83, 182, 53, 1);
  border-radius: 50px;
  color: rgba(238, 238, 238, 1);
  font-size: 14px;
  font-weight: 500;
  width: 128px;
  height: 40px;
  border: 0;
  outline: 0;
  cursor: default;
}

.enroll_student_payment_table table tbody td p {
  color: rgba(59, 143, 238, 1);
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.enroll_student_payment_table table tbody .enroll_student_payment_modal {
  position: fixed;
  bottom: 10px;
  right: 10px;
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  background-color: white;
  border-radius: 4px;
  padding: 30px;
  width: 600px;
  z-index: 99;
}

.enroll_student_payment_modal .cross_icon {
  margin-left: auto;
  font-size: 24px;
  display: block;
  cursor: pointer;
}
.enroll_student_payment_modal .enroll_student_payment_modal_input_div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
}

.enroll_student_payment_modal_input_div .enroll_student_payment_modal_input {
  display: flex;
  flex-direction: column;
  width: 250px;
}

.enroll_student_payment_modal_input label {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
  color: rgba(94, 99, 102, 1);
}

.enroll_student_payment_modal_input input,
select {
  padding: 8px 16px;
  color: rgba(171, 175, 177, 1);
  font-weight: 400;
  border: 0;
  border-radius: 8px;
  height: 48px;
  outline: 0;
  font-size: 14px;
  background-color: rgba(239, 241, 249, 0.6);
}

.enroll_student_payment_modal_input .enroll_student_payment_modal_buttons {
  display: flex;
  gap: 14px;
}

.enroll_student_payment_modal_buttons button {
  border: 1px solid rgba(224, 224, 224, 1);
  background: transparent;
  border-radius: 8px;
  padding: 10px 18px;
  color: rgba(130, 130, 130, 1);
  font-size: 14px;
  font-weight: 400;
}

.enroll_student_payment_modal_buttons .active {
  background-color: #024c87;
  border: 1px solid #024c87;
  color: #fff;
}

/* .enroll_student_payment_modal_buttons */
/* .enroll_student_payment_modal_buttons button {
  background-color: #0057ff;
} */

/* .enroll_student_payment_modal_buttons */
.enroll_student_offer_letter_container {
  margin: 0px 20px;
  padding: 30px 35px;
  border: 1px solid rgba(221, 226, 229, 1);
  border-radius: 20px;
  margin-bottom: 0;
  height: 72vh;
  overflow-y: auto;
}

.enroll_student_offer_letter_container h2 {
  font-size: 24px;
  color: rgba(16, 24, 40, 1);
  font-weight: 600;
}

.enroll_student_offer_letter_container .enroll_student_offer_letter_title {
  border: 1px solid rgba(140, 140, 140, 1);
  padding: 10px;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 400;
  width: fit-content;
  border-radius: 4px;
}

.enroll_student_offer_letter_container
  .enroll_student_offer_letter_description {
  border: 1px solid rgba(140, 140, 140, 1);
  padding: 10px;
  border-radius: 4px;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}

.enroll_student_offer_letter_container
  .enroll_student_offer_letter_description:last-child {
  margin-bottom: 0;
}

/*  */

.enroll_student_invoice_container {
  padding: 37px 43px;
  border: 1px solid rgba(221, 226, 229, 1);
  border-radius: 15px;
  height: 70vh;
  overflow-y: auto;
  margin: 0px 10px;
}

.enroll_student_invoice_container h2 {
  color: rgba(16, 24, 40, 1);
  font-size: 24px;
  font-weight: 600;
}

.enroll_student_invoice_container .enroll_student_invoice_form_2col {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.enroll_student_invoice_form_2col .enroll_student_invoice_input_div {
  width: 47%;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.enroll_student_invoice_input_div .enroll_student_invoice_select {
  position: relative;
}

.enroll_student_invoice_select .down_icon {
  position: absolute;
  font-size: 18px;
  right: 10px;
  top: 12px;
}

.enroll_student_invoice_input_div label {
  font-size: 16px;
  font-weight: 400;
  color: rgba(94, 99, 102, 1);
}

.enroll_student_invoice_input_div input,
select {
  background-color: rgba(239, 241, 249, 0.6);
  border: 0;
  outline: 0;
  padding: 8px 16px;
  height: 43px;
  width: 100%;
  border-radius: 8px;
  color: rgba(171, 175, 177, 1);
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
}

.enroll_student_invoice_input_div .enroll_student_invoice_gender {
  display: flex;
  gap: 15px;
}

.enroll_student_invoice_gender button {
  border: 1.5px solid rgba(224, 224, 224, 1);
  /* border: 0; */
  outline: 0;
  padding: 10px 20px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(130, 130, 130, 1);
  background: transparent;
  border-radius: 6px;
}

.enroll_student_invoice_input_div .enroll_student_invoice_gender .active {
  background-color: #024c87;
  color: #fff;
  border: 1px solid #024c87;
}

.enroll_student_invoice_container .enroll_student_invoice_form_3col {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.enroll_student_invoice_form_3col .enroll_student_invoice_input_div {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-top: 20px;
}

.enroll_student_invoice_switch_option {
  display: flex;
  margin-top: 20px;
  gap: 20%;
}

.enroll_student_invoice_switch_option
  .enroll_student_invoice_switch_option_div {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: rgba(94, 99, 102, 1);
  font-weight: 400;
}

.enroll_student_invoice_switch_option_div .enroll_student_switch {
  margin-left: 0;
}

.enroll_student_invoice_container .enroll_student_invoice_send_button {
  display: flex;
  margin-top: 30px;
  margin-left: auto;
  align-items: center;
  justify-content: end;
  gap: 20px;
}

.enroll_student_invoice_send_button button {
  padding: 12px 24px;
  border-radius: 12px;
  font-size: 16px;
  border: 0;
  background: transparent;
  outline: 0;
  font-weight: 400;
  cursor: pointer;
}

.enroll_student_invoice_send_button button:first-child {
  background-color: rgba(85, 112, 241, 1);
  color: rgb(255, 255, 255);
}

.enroll_student_invoice_send_button button:last-child {
  border: 2px solid rgba(85, 112, 241, 1);
  color: rgba(85, 112, 241, 1);
}

/* start */

/* .enroll_student_step_bar {
  position: relative;
} */

.enroll_student_step_bar {
  /* margin-bottom: 20px; */
  padding: 0px 20px 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.enroll_student_step_bar::before {
  content: "";
  border: 1px solid rgba(124, 139, 157, 1);
  width: 91%;
  position: absolute;
  top: 24px;
  left: 66px;
}

.enroll_student_step_bar .enroll_student_step_box {
  border: 4.68px solid rgba(124, 139, 157, 1);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  padding: 2px;
  background-color: white;
  z-index: 9;
  position: relative;
}

.enroll_student_step_bar .enroll_student_step_box.active {
  width: 55px;
  height: 55px;
}

.enroll_student_step_box .enroll_student_step_box_content {
  background-color: #f4c300;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  z-index: 9;
  position: relative;
  cursor: pointer;
}

.enroll_student_step_box .enroll_student_step_box_content.active {
  background-color: rgb(0, 186, 0);
}

.enroll_student_step_box_div {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  flex-direction: column;
}

.enroll_student_step_box_div p {
  color: rgba(71, 84, 103, 1);
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

/*  */

/*  */
.offer_letter_table_head {
  display: flex;
  align-items: center;
  padding: 16px 20px;
  background-color: white;
  position: fixed;
  width: inherit;
  box-shadow: 0px 0px 4px 0px #d9d9d9;
}

.offer_letter_table_head h2 {
  color: rgba(45, 44, 44, 1);
  font-size: 24px;
  font-weight: 500;
  display: flex;
  margin: 0;
  align-items: center;
  cursor: pointer;
}

.offer_letter_table_head .offer_letter_table_head_icon_div {
  margin-left: auto;
  display: flex;
  gap: 15px;
  margin-right: 30px;
  align-items: center;
}

.offer_letter_table_head_icon_div .offer_letter_table_head_search_icon {
  position: relative;
  transition: all 1s;
  width: 35px;
  height: 35px;
  background: white;
  box-sizing: border-box;
  border: 4px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: 5px;
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  cursor: pointer;
  border-radius: 4px;
}

.offer_letter_table_head_search_icon input {
  position: absolute;
  top: -2;
  left: 0;
  width: 100%;
  /* height: 42.5px; */
  line-height: 30px;
  outline: 0;
  border: 0;
  display: none;
  font-size: 16px;
  border-radius: 20px;
  padding: 0 20px;
  cursor: pointer;
}

.offer_letter_table_head_search_icon .oflettersearch {
  /* font-size: 15px; */
  width: 16px;
}

.offer_letter_table_head_search_icon.expanded {
  width: 300px;
  background-color: white;
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  border-radius: 50px;
}

/* .offer_letter_table_head_search_icon:hover {
  width: 300px;
  background-color: white;
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  border-radius: 50px;
} */

.offer_letter_table_head_search_icon.expanded input {
  display: block;
  cursor: auto;
}

.offer_letter_table_head_search_icon.expanded .search_icon_svg {
  box-sizing: border-box;
  color: #07051a;
  text-align: center;
  /* font-size: 12px; */
  transition: all 1s;
  position: absolute;
  right: 6px;
  width: 16px;
  top: 6px;
}

/* .offer_letter_table_head_search_icon
  .offer_letter_table_head_search_icon
  .search_icon_svg {
  box-sizing: border-box;
  color: #07051a;
  text-align: center;
  font-size: 12px;
  transition: all 1s;
  position: absolute;
  right: 2px;
  width: 20px;
  top: 3px;
} */

/* .offer_letter_table_head_icon_div .search_icon {
  position: relative;
}

.offer_letter_table_head_icon_div
  .search_icon
  .offer_letter_table_head_search_input {
  position: absolute;
  width: 315px;
  right: -2px;
  height: 40px;
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  transition: all 0.4s;
}

.offer_letter_table_head_search_input .search_input_icon {
  position: absolute;
  top: 10px;
  left: 10px;
}

.offer_letter_table_head_search_input input {
  width: 100%;
  height: 100%;
  border: 0;
  color: rgba(187, 187, 187, 1);
  font-size: 16px;
  font-weight: 400;
  outline: 0;
  padding-left: 37px;
} */

.offer_letter_table_head_icon {
  width: 35px;
  background-color: white;
  height: 35px;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  font-size: 20px;
  color: rgba(113, 113, 113, 1);
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  position: relative;
}

.offer_letter_table_head_icon img {
  width: 18px;
}

.offer_letter_table_head .offer_letter_table_head_btn {
  background-color: rgba(2, 76, 135, 1);
  border-radius: 6px;
  width: 111px;
  height: 35px;
  border: 0;
  outline: 0;
  font-size: 16px;
  font-weight: 400;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.offer_letter_table_head button .offer_letter_table_dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid rgba(213, 217, 221, 1);
  width: 170px;
  /* height: 68px; */
  border-radius: 6px;
  top: 43px;
  padding: 8px 10px;
  right: 0;
}

.offer_letter_table_dropdown span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(82, 81, 81, 1);
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 14px;
}

.offer_letter_table_dropdown span:last-child {
  margin: 0;
}

.offer_letter_table_container {
  padding: 20px;
  background-color: white;
  /* height: 78vh; */
  /* margin: 0px 10px; */
  /* overflow-y: auto; */
}

.offer_letter_table_container .offer_letter_table_div {
  /* box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); */
  border: 1px solid rgba(228, 231, 236, 1);
  border-radius: 12px;
  overflow: hidden;
  margin-top: 65px;
}

.offer_letter_table_div table {
  width: 100%;
}

.offer_letter_table_div table thead {
  background-color: rgba(249, 250, 251, 1);
}

.offer_letter_table_div table thead th {
  padding: 12px;
  border-bottom: 1px solid rgba(234, 236, 240, 1);
  color: rgba(71, 84, 103, 1);
  font-size: 16px;
  font-weight: 500;
}

.offer_letter_table_div table tbody td {
  padding: 12px;
  color: rgba(102, 112, 133, 1);
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid rgba(234, 236, 240, 1);
}

.offer_letter_table_div table thead .checkbox_input {
  min-width: 80px;
}

.offer_letter_table_div table tbody .checkbox_input {
  width: 30px;
}

.offer_letter_table_div table tbody td .offer_letter_table_name {
  display: flex;
  align-items: center;
  gap: 10px;
}

.offer_letter_table_name img {
  width: 40px;
  border-radius: 50%;
  height: 40px;
  object-fit: cover;
}

.offer_letter_table_name span h3 {
  font-size: 14px;
  font-weight: 500;
  color: rgba(16, 24, 40, 1);
  margin: 0;
  text-decoration: underline;
}

.offer_letter_table_name span p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(92, 92, 92, 1);
  margin: 0;
}

.offer_letter_table_div .offer_letter_table_paginate ul {
  margin: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.offer_letter_table_div .offer_letter_table_paginate ul li a {
  padding: 8px 18px;
}

.offer_letter_table_div .offer_letter_table_paginate ul .active a {
  background-color: #e7e7e7;
  border-radius: 8px;
}

/*  */
.send_offer_letter_nav_header {
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 16px 36px;
  background-color: white;
}

.send_offer_letter_nav_header span {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  color: rgba(45, 44, 44, 1);
}

.send_offer_letter_nav_input {
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  width: 311px;
  height: 44px;
  border-radius: 8px;
  position: relative;
}

.send_offer_letter_nav_input .send_offer_letter_nav_search_icon {
  position: absolute;
  font-size: 20px;
  top: 12px;
  left: 12px;
}

.send_offer_letter_nav_input input {
  border: 0;
  width: 100%;
  border-radius: 8px;
  height: 100%;
  color: rgba(187, 187, 187, 1);
  font-size: 20px;
  font-weight: 400;
  padding-left: 50px;
  outline: 0;
  padding-left: 36px;
}

.send_offer_letter_form_container {
  padding: 0px 15px;
  /* height: 65vh; */
  /* overflow-y: auto; */
}

.send_offer_letter_form_container .send_offer_letter_form {
  border: 1px solid rgba(221, 226, 229, 1);
  border-radius: 15px;
  padding: 37px 40px;
}

.send_offer_letter_form .send_offer_letter_form_div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.send_offer_letter_form_div .send_offer_letter_input {
  display: flex;
  flex-direction: column;
  width: 48%;
  gap: 5px;
  margin-bottom: 24px;
}

.send_offer_letter_form_div .send_offer_letter_select {
  position: relative;
}

.send_offer_letter_select .down_icon {
  position: absolute;
  right: 10px;
  top: 16px;
  font-size: 18px;
}

.send_offer_letter_input label {
  color: rgba(94, 99, 102, 1);
  font-size: 16px;
  font-weight: 400;
}

.send_offer_letter_input input,
select {
  background-color: rgba(239, 241, 249, 0.6);
  border: none;
  outline: none;
  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  height: 50px;
  color: rgba(171, 175, 177, 1);
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  appearance: none;
}

.send_offer_letter_input .send_offer_letter_gender_button {
  display: flex;
  align-items: center;
  gap: 20px;
}

.send_offer_letter_input .send_offer_letter_gender_button button {
  border: 1.5px solid rgba(224, 224, 224, 1);
  padding: 10px 20px;
  border-radius: 6px;
  background: transparent;
  color: rgba(130, 130, 130, 1);
  font-weight: 400;
  font-size: 14px;
}

.send_offer_letter_input .send_offer_letter_gender_button .active {
  background-color: #024c87;
  color: #fff;
  border: 1px solid #024c87;
}

.send_offer_letter_form .send_offer_letter_form_buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.send_offer_letter_form_buttons button {
  border-radius: 10px;
  width: 180px;
  height: 50px;
  border: 0;
  outline: 0;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.send_offer_letter_form_buttons button:first-child {
  background: transparent;
  border: 2px solid rgba(85, 112, 241, 1);
  color: rgba(85, 112, 241, 1);
}

.send_offer_letter_form_buttons button:last-child {
  background-color: rgba(85, 112, 241, 1);
  color: white;
}

.compose_email_edit_text .ql-container {
  min-height: 150px;
}

.compose_email_edit_text {
  margin-top: 40px;
}

.enroll_student_register_container {
  border: 1px solid #dde2e5;
  padding: 35px 40px;
  border-radius: 18px;
  margin: 10px;
}

.enroll_student_register_container h2 {
  font-size: 24px;
  font-weight: 600;
  color: #101828;
  margin-bottom: 14px;
}

.enroll_student_register_container .enroll_student_register_info {
  border: 1px solid #8c8c8c;
  border-radius: 4px;
  padding: 12px;
  width: fit-content;
  margin-bottom: 15px;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
}

.enroll_student_register_info span {
  margin-bottom: 10px;
  display: block;
}

.enroll_student_register_info ul {
  list-style: none;
}

.enroll_student_register_info ul li {
  margin-bottom: 16px;
}

.enroll_student_register_info .enroll_student_register_note {
  padding-left: 0;
}

.enroll_student_register_container button {
  border: 2px solid #5570f1;
  border-radius: 12px;
  padding: 16px 24px;
  background: transparent;
  outline: 0;
  font-size: 16px;
  font-weight: 400;
  color: #5570f1;
  margin-left: auto;
  display: block;
}

.compose_email_edit_text .ql-container {
  min-height: 150px;
}

.compose_email_edit_text {
  margin-top: 40px;
}

.enroll_student_remind_info ul {
  padding-left: 0;
  margin-bottom: 0;
}

.enroll_student_remind_info ul li {
  margin: 0;
}

.enroll_student_remind_info p {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  margin: 0;
}

.enroll_student_remind_meeting h4 {
  font-size: 16px;
  color: #333333;
  font-weight: 400;
  margin: 0;
}

.enroll_student_remind_meeting h5 {
  font-size: 16px;
  color: #333333;
  font-weight: 400;
  margin-bottom: 20px;
}

.enroll_student_remind_meeting span {
  margin-bottom: 0;
  padding-block: 10px;
}

.enroll_student_telegram_training ul {
  padding-left: 0;
  margin-top: 14px;
  margin-bottom: 0;
}

.enroll_student_telegram_training ul li {
  margin-bottom: 0;
}

.enroll_student_telegram_training_material p {
  font-size: 16px;
  color: #333333;
  font-weight: 400;
}

.enroll_student_telegram_link p {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  text-decoration: underline;
}

.enroll_student_ticket_container {
  padding: 0 15px;
  height: 50vh;
  overflow-y: auto;
}

.enroll_student_ticket_container .enroll_student_ticket_list {
  display: flex;
  gap: 10px;
  padding-bottom: 25px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 20px;
}

.enroll_student_ticket_list img {
  width: 70px;
  height: 70px;
  background-color: #eaeaea;
  border-radius: 50%;
  object-fit: cover;
}

.enroll_student_ticket_list .enroll_student_ticket_list_content {
  width: 100%;
}

.enroll_student_ticket_list_content p {
  color: #565656;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}

.enroll_student_ticket_list_content .enroll_student_ticket_list_content_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.enroll_student_ticket_list_content_top h2 {
  font-size: 20px;
  font-weight: 500;
  color: #343434;
  margin: 0;
}

.enroll_student_ticket_list_content_top h2 span {
  font-size: 18px;
  font-weight: 400;
  color: #939393;
}

.enroll_student_ticket_list_content_top h3 {
  color: #939393;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

.enroll_student_ticket_list_content_top h3 span {
  color: #484848;
  font-size: 24px;
}

.enroll_student_certificate_container {
  padding: 20px;
  height: 50vh;
  overflow-y: auto;
}

.enroll_student_certificate_container .enroll_student_certificate_div {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.enroll_student_certificate_div .enroll_student_certificate_box {
  width: 250px;
  height: 360px;
  position: relative;
  box-shadow: 0px 0px 4px 0px #bcbcbc;
  border-radius: 6px;
  overflow: hidden;
}

.enroll_student_certificate_div .enroll_student_certificate_box img {
  width: 100%;
  height: 100%;
}

.enroll_student_certificate_box .overlay {
  position: absolute;
  bottom: 0;
  background-color: white;
  left: 0;
  border-top: 1px solid #d9d9d9;
  width: 100%;
  padding: 14px 12px;
}

.enroll_student_certificate_box .overlay h4 {
  color: #333333;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0;
}

.enroll_student_certificate_box .overlay p {
  font-size: 16px;
  font-weight: 500;
  color: #5c5c5c;
  margin: 0;
}

.enroll_student_certificate_container p {
  display: flex;
  margin: 30px;
  align-items: center;
  gap: 10px;
}

.enroll_student_certificate_container p span {
  font-weight: 500;
  font-size: 24px;
  color: #5c5c5c;
}

.enroll_student_certificate_container p h6 {
  font-size: 15px;
  font-weight: 400;
  color: #5c5c5c;
  margin-top: 12px;
}

.enroll_student_certificate_container button {
  border: 2px solid #5570f1;
  padding: 16px 24px;
  color: #5570f1;
  font-size: 16px;
  font-weight: 400;
  border-radius: 12px;
  display: block;
  margin-left: auto;
  background: transparent;
}

.enroll_student_certificate_form_container {
  box-shadow: 0px 0px 4px 0px #d9d9d9;
  border-radius: 8px;
  padding: 32px 56px;
  width: 800px;
  height: 72vh;
  margin-top: 20px;
  overflow-y: auto;
  position: relative;
}

.enroll_student_certificate_form_container h2 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #576bad;
  margin-bottom: 30px;
}

.enroll_student_certificate_form_container form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.enroll_student_certificate_form_container
  form
  .enroll_student_certificate_form_input {
  width: 47%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
}

.enroll_student_certificate_form_input .down_icon {
  position: absolute;
  right: 2%;
  top: 53%;
  font-size: 22px;
  color: #737373;
}

.enroll_student_certificate_form_container
  form
  .enroll_student_certificate_form_select {
  width: 100%;
}

.enroll_student_certificate_form_input label {
  color: #576bad;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 4px;
}

.enroll_student_certificate_form_input input,
select {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 16px;
  background: transparent;
  height: 50px;
  outline: 0;
}

.enroll_student_certificate_form_input .enroll_student_certificate_radio_div {
  display: flex;
  gap: 20px;
}

.enroll_student_certificate_radio_div .enroll_student_certificate_radio_box {
  display: flex;
  align-items: center;
  gap: 20px;
}

.enroll_student_certificate_radio_div
  .enroll_student_certificate_radio_box
  input {
  height: 20px;
  width: 20px;
}

.enroll_student_certificate_radio_box h4 {
  font-size: 16px;
  font-weight: 400;
  color: #737373;
  border: 1px solid #e3e3e3;
  margin: 0;
  border-radius: 5px;
  width: max-content;
  padding: 12px 24px;
}

.enroll_student_certificate_form_container
  .enroll_student_certificate_send_button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.enroll_student_certificate_send_button button {
  background-color: #6aac29;
  padding: 12px 40px;
  font-size: 20px;
  font-weight: 500;
  color: white;
  display: block;
  outline: 0;
  border-radius: 50px;
  border: 0;
}

.enroll_student_certificate_form_container
  .enroll_student_certificate_cross_icon {
  position: absolute;
  font-size: 20px;
  color: #a80000;
  top: 4%;
  right: 4%;
  cursor: pointer;
}

.send_offer_letter_input .send_offer_letter_phone_input {
  display: flex;
  gap: 10px;
}

.send_offer_letter_phone_input .send_offer_letter_phone_input_select {
  position: relative;
  background-color: rgba(239, 241, 249, 0.6);
  border-radius: 8px;
}

.send_offer_letter_phone_input_select .down_icon {
  position: absolute;
  right: 9px;
  top: 15px;
}

.send_offer_letter_phone_input select {
  background-color: rgba(239, 241, 249, 0.6);
  border: 0;
  color: rgba(171, 175, 177, 1);
  font-size: 14px;
  cursor: pointer;
  width: 120px;
}

.enroll_student_offer_letter_container input {
  margin-bottom: 20px;
  border: 0;
  font-size: 20px;
  font-weight: 300;
  padding-bottom: 12px;
  width: 100%;
  outline: 0;
  color: #a6a7a7;
  border-bottom: 1px solid #d9d9d9;
}

.enroll_student_offer_letter_container
  .enroll_student_offer_letter_edit_input
  .ql-container {
  min-height: 120px;
  font-size: 14px;
}

.enroll_student_offer_letter_edit_input .ql-toolbar.ql-snow {
  border-radius: 5px 5px 0px 0px;
}

.enroll_student_offer_letter_container .enroll_student_offer_letter_button {
  padding: 12px 24px;
  border: 2px solid #5570f1;
  outline: 0;
  border-radius: 12px;
  color: #5570f1;
  font-size: 16px;
  font-weight: 400;
  background: transparent;
  margin-left: auto;
  display: block;
  margin-top: 20px;
}

.enroll_student_invoice_input_div .send_offer_letter_phone_input {
  display: flex;
  align-items: center;
  gap: 10px;
}

.enroll_student_invoice_input_div .send_offer_letter_phone_input_select select {
  height: 100%;
}

.ticket_queries_header_right .ticket_queries_header_right_dropdown {
  position: absolute;
  background-color: white;
  top: 30px;
  right: 0;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 6px;
  list-style: none;
  z-index: 99;
  width: 100px;
}

.ticket_queries_header_right_dropdown li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 4px;
}

/* .offer_letter_table_head .offer_letter_table_head_icon_div */
/* .offer_letter_table_head_icon_div .offer_letter_table_head_icon */

.email_chats_content_head .active {
  width: 100px;
}

/* start  */
/* .enroll_student_step_bar .enroll_student_step_box */
/* .enroll_student_step_bar::before */
/* end  */

/* .send_offer_letter_form_container */
/* .enroll_student_offer_letter_container */
/* .enroll_student_details_container .enroll_student_details_header  */
/* .enroll_student_details_container */
/* enroll_student_payment_table */
/* .enroll_student_invoice_container */
/* .enroll_student_certificate_form_container  */
/* .offer_letter_table_div table thead .checkbox_input */

.table-template-style tbody tr td {
  font-size: 14px;
}

.react_paginate_Div ul {
  justify-content: space-between;
  align-items: center;
}

.react_paginate_Div ul li:first-child {
  margin-right: auto;
}
.react_paginate_Div ul li:last-child {
  margin-left: auto;
}

.filter_options_container .filter_apply_btn button {
  font-size: 16px;
}

.filter_options_course .filter_options_course_item {
  font-size: 14px;
}
.filter_options_container .filter_options_course {
  gap: 10px;
  justify-content: flex-start;
}
.filter_options_container .filter_options_inp span {
  font-size: 16px;
}

.filter_options_container .filter_options_div h4 {
  color: #242220;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}

.filter_options_container .filter_options_labels svg {
  font-size: 15px;
  color: rgba(153, 153, 153, 1);
}

.lead_table_searchbar .form_table_nav_input {
  font-size: 16px;
  /* width: 100%; */
  outline: none;
  padding: 0px 22px;
  border-radius: 6px;
  border: 1px solid rgba(217, 217, 217, 1);
  /* font-size: 14px; */
  font-weight: 400;
  color: rgba(154, 163, 171, 1);
}

.form_table_nav_search .form_table_nav_input {
  font-size: 16px;
  width: 300px;
  outline: none;
  padding: 0 16px;
  border-radius: 6px;
  border: 1px solid rgba(217, 217, 217, 1);
  /* font-size: 14px; */
  font-weight: 400;
  color: rgba(154, 163, 171, 1);
}

.form_table_nav_input .form_table_nav_input_search {
  font-size: 20px;
  /* margin-left: 10px; */
  /* position: absolute; */
  color: rgba(154, 163, 171, 1);
  cursor: pointer;
  /* top: 9px; */
  /* left: 9px; */
  /* font-size: 20px; */
}

.form_table_nav_search .form_table_nav_input input {
  color: rgba(154, 163, 171, 1);
}

.form_table_nav_search .form_table_nav_input input::placeholder {
  color: rgba(154, 163, 171, 1);
}
.lead_table_searchbar .form_table_nav_input input::placeholder {
  color: rgba(154, 163, 171, 1);
}

.filter_options_container .filter_options_batch_label {
  padding: 9px;
  border: 1px solid hsl(0, 0%, 70%);
}

.filter_options_container .filter_options_labels label {
  color: hsl(0, 0%, 50%);
}

.forms_table_container {
  height: 100vh;
  padding: 0;
}

/*  */
.form_header {
  display: flex;
  align-items: center;
  /* padding: 16px 20px; */
  color: rgba(45, 44, 44, 1);
  cursor: pointer;
}

.form_header h2 {
  font-size: 24px;
  margin: 0;
  font-weight: 500;
}

.form_table_navs_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
  padding: 10px 20px;
  /*  */
  background-color: white;
  position: fixed;
  width: -webkit-fill-available;
  box-shadow: 0px 0px 3px 0px #d9d9d9;
}

.form_table_nav_search {
  width: auto;
  justify-content: end;
}

.form_table_nav_filter {
  width: 35px;
  background-color: white;
  height: 35px;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  font-size: 20px;
  color: rgba(113, 113, 113, 1);
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.form_table_nav_filter img {
  width: 18px;
}

.offer_letter_table_head_icon .rows_dropdown_list {
  position: absolute;
  top: 45px;
  right: 0;
  width: 130px;
  background-color: white;
  box-shadow: 0px 0px 4px 0px #0000001f;
  padding: 6px;
  border-radius: 6px;
}

.offer_letter_table_head_icon .rows_dropdown_list span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 5px;
  font-size: 16px;
  color: #06110a80;
  cursor: pointer;
}

.offer_letter_table_head_icon .rows_dropdown_list span input {
  width: 10px;
  height: 10px;
}
.offer_letter_table_head_icon .rows_dropdown_list span label {
  cursor: pointer;
}

.offer_letter_table_head_icon .rows_dropdown_list span.active {
  background: #eff6ff;
  border-radius: 4px;
  color: #000000;
}

.module_student_table {
  margin-top: 65px;
}

.lead_table_container {
  margin-top: 80px;
}

.forms_table_container .react_paginate_Div .prev-button {
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  color: black;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  outline: none;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  /* border: 1px solid #d0d5dd; */
}

.filter_options_container .filter_options_div {
  position: relative;
}

.filter_options_container .filter_options_calendar {
  position: absolute;
  top: 70px;
  width: 100%;
  background-color: white;
  z-index: 99;
}

.filter_options_container .filter_options_batches {
  position: absolute;
  z-index: 99;
  width: 96%;
}

.enroll_student_profile_container {
  width: 100%;
  background-color: white;
  /* height: 100vh; */
}

.enroll_student_profile_container .enroll_student_profile_header {
  display: flex;
  align-items: center;
  padding: 16px 20px;
  background-color: white;
  position: fixed;
  width: inherit;
  box-shadow: 0px 0px 4px 0px #d9d9d9;
}

.enroll_student_profile_container h2 {
  color: rgba(45, 44, 44, 1);
  font-size: 24px;
  font-weight: 500;
  display: flex;
  margin: 0;
  align-items: center;
}

.enroll_student_profile_container .enroll_student_profile_details {
  /* padding: 20px; */
  padding-top: 8%;
  padding-bottom: 1%;
}

.enroll_student_profile_details .enroll_student_profile_img_div label {
  width: 150px;
  height: 150px;
  margin: auto;
  display: block;
  border-radius: 50%;
  position: relative;
}

.enroll_student_profile_img_div label img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.enroll_student_profile_img_div label svg {
  position: absolute;
  right: 6px;
  bottom: 6px;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 50%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.enroll_student_profile_container .enroll_student_profile_personal_details {
  margin-top: 40px;
}

.enroll_student_profile_container
  .enroll_student_profile_personal_details:last-child {
  margin-top: 0;
}

.enroll_student_profile_personal_details .enroll_student_profile_input_div {
  display: flex;
  flex-direction: column;
  padding-inline: 20px;
}

.enroll_student_profile_input_div label {
  color: var(--primary-blue);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
}

.enroll_student_profile_input_div input {
  border-radius: 5px;
  border: 1px solid var(--primary-white);
  padding: 10px 14px;
  outline: none;
  width: 100%;
}

.enroll_student_profile_personal_details button {
  border-radius: 5px;
  background: var(--primary-violet);
  padding: 8px 22px;
  color: var(--main-white);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 144.5%;
  text-transform: capitalize;
  border: 1px solid var(--primary-violet);
  margin-top: 10px;
  display: block;
  margin-inline: 20px;
  margin-left: auto;
}

.form_table_nav_search .offer_letter_table_head_search_icon {
  position: relative;
  transition: all 1s;
  width: 35px;
  height: 35px;
  background: white;
  box-sizing: border-box;
  border: 4px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: 5px;
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  cursor: pointer;
  border-radius: 4px;
}

.offer_letter_table_head_search_icon input {
  position: absolute;
  top: -2;
  left: 0;
  width: 100%;
  /* height: 42.5px; */
  line-height: 30px;
  outline: 0;
  border: 0;
  display: none;
  font-size: 16px;
  border-radius: 20px;
  padding: 0 20px;
  cursor: pointer;
}

.offer_letter_table_head_search_icon .oflettersearch {
  /* font-size: 15px; */
  width: 16px;
}

.offer_letter_table_head_search_icon.expanded {
  width: 300px;
  background-color: white;
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  border-radius: 50px;
}

/* .ticket_queries_outer_container {
  height: 100vh;
} */

/* .ticket_queries_outer_container */
/* .ticket_queries_outer_container .ticket_queries_inner_container */
/* .ticket_chats_container */
/* .email_chat_list_container */
/* .lead_table_searchbar */

/*  */
.lead_table_searchbar .offer_letter_table_head_search_icon {
  position: relative;
  transition: all 1s;
  width: 35px;
  height: 35px;
  background: white;
  box-sizing: border-box;
  border: 4px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: 5px;
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  cursor: pointer;
  border-radius: 4px;
}

.offer_letter_table_head_search_icon input {
  position: absolute;
  top: -2;
  left: 0;
  width: 100%;
  /* height: 42.5px; */
  line-height: 30px;
  outline: 0;
  border: 0;
  display: none;
  font-size: 16px;
  border-radius: 20px;
  padding: 0 20px;
  cursor: pointer;
}

.offer_letter_table_head_search_icon .oflettersearch {
  /* font-size: 15px; */
  width: 16px;
}

.offer_letter_table_head_search_icon.expanded {
  width: 300px;
  background-color: white;
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  border-radius: 50px;
}

/*  */
.email_header_right_side .offer_letter_table_head_search_icon {
  position: relative;
  transition: all 1s;
  width: 35px;
  height: 35px;
  background: white;
  box-sizing: border-box;
  border: 4px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: 5px;
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  cursor: pointer;
  border-radius: 4px;
}

.offer_letter_table_head_search_icon input {
  position: absolute;
  top: -2;
  left: 0;
  width: 100%;
  /* height: 42.5px; */
  line-height: 30px;
  outline: 0;
  border: 0;
  display: none;
  font-size: 16px;
  border-radius: 20px;
  padding: 0 20px;
  cursor: pointer;
}

.offer_letter_table_head_search_icon .oflettersearch {
  /* font-size: 15px; */
  width: 16px;
}

.offer_letter_table_head_search_icon.expanded {
  width: 300px;
  background-color: white;
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  border-radius: 50px;
}

/*  */
.table-template-style tbody tr td {
  /* min-width: 0; */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.compose_email_edit_text .ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value);
}

.compose_email_edit_text
  .ql-snow
  .ql-picker.ql-header
  .ql-picker-item[data-value="1"]::before,
.compose_email_edit_text
  .ql-snow
  .ql-picker.ql-header
  .ql-picker-label[data-value="1"]::before {
  content: "32";
  font-size: 14px;
}

.compose_email_edit_text
  .ql-snow
  .ql-picker.ql-header
  .ql-picker-item[data-value="2"]::before,
.compose_email_edit_text
  .ql-snow
  .ql-picker.ql-header
  .ql-picker-label[data-value="2"]::before {
  content: "24";
  font-size: 14px;
}

.compose_email_edit_text
  .ql-snow
  .ql-picker.ql-header
  .ql-picker-item[data-value="3"]::before,
.compose_email_edit_text
  .ql-snow
  .ql-picker.ql-header
  .ql-picker-label[data-value="3"]::before {
  content: "18";
  font-size: 14px;
}

/* .ql-size-10px { font-size: 10px; }
.ql-size-12px { font-size: 12px; }
.ql-size-14px { font-size: 14px; }
.ql-size-16px { font-size: 16px; }
.ql-size-18px { font-size: 18px; }
.ql-size-24px { font-size: 24px; }
.ql-size-32px { font-size: 32px; } */

.formstudent_info_right {
  padding: 14px;
}

.formstudent_info_right .formstudent_info_right_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.formstudent_info_right_header h2 {
  font-size: 24px;
  font-weight: 500;
  color: rgba(78, 78, 78, 1);
}

.formstudent_info_right_header h4 {
  color: rgba(140, 140, 140, 1);
  font-size: 16px;
  font-weight: 400;
}

.formstudent_info_right .accordion-item {
  box-shadow: 0px 0px 5px 0px rgba(220, 220, 220, 1);
  border: 0;
}

.formstudent_info_right .accordion-button {
  box-shadow: none;
  background-color: white;
  position: relative;
}

.formstudent_info_right .accordion-button::after {
  display: inline-block;
  position: absolute;
  top: 30%;
  /* transform: translateY(-50%); */
  right: 15px;
}

.formstudent_info_right .formstudent_info_right_acc_header {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.formstudent_info_right .formstudent_info_right_acc_header img {
  width: 44px;
  height: 44px;
}

.formstudent_info_right_acc_header span h4 {
  color: rgba(52, 52, 52, 1);
  font-size: 18px;
  margin: 0;
}

.formstudent_info_right_acc_header span p {
  color: rgba(140, 140, 140, 1);
  font-size: 16px;
  margin: 0;
}

.formstudent_info_right .formstudent_info_right_acc_body h3 {
  font-size: 24px;
  font-weight: 600;
}

.formstudent_info_right .formstudent_info_right_acc_body p {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.formstudent_info_right_acc_body input {
  border: 0;
  border-bottom: 1px solid rgba(220, 220, 220, 1);
  padding: 6px;
  font-size: 16px;
  margin-bottom: 10px;
  outline: 0;
}

.formstudent_info_right_acc_body p svg {
  color: rgba(188, 188, 188, 1);
  cursor: pointer;
}

.formstudent_info_right_acc_body .formstudent_info_right_acc_body_check {
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 10px;
  margin-top: 10px;
}

.formstudent_info_right_acc_body_check input {
  margin: 0;
  width: 16px;
  height: 16px;
}

.formstudent_info_right_acc_body .formstudent_info_right_acc_body_send {
  background-color: rgba(82, 187, 56, 1);
  border-radius: 8px;
  color: white;
  text-transform: capitalize;
  padding-block: 6px;
}

.formstudent_info_right button {
  background-color: rgba(82, 187, 56, 1);
  padding: 12px 16px;
  font-size: 16px;
  border-radius: 6px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  border: 0;
  outline: 0;
  display: block;
  margin-left: auto;
  margin-top: 20px;
}

/* start */

/* end */


.formstudent_info_container .formstudent_info_right_acc_body_svg svg  {
  font-size: 25px ;
  cursor: pointer;
}
/* formstudent_info_right_acc_body_submit */